import React from 'react';

interface CorreaSanguinoLogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'original' | 'black' | 'white';
}

const CorreaSanguinoLogo: React.FC<CorreaSanguinoLogoProps> = ({
  variant = 'original',
  ...props
}) => {
  const getColor = (defaultColor: string) => {
    switch (variant) {
      case 'black':
        return '#3D3D3D';
      case 'white':
        return '#E8E8ED';
      default:
        return defaultColor;
    }
  };

  return (
    <svg
      {...props}
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 280 46.98"
    >
      <g>
        <path
          fill={getColor('#91959e')}
          d="M12.23,0c3.52,0,9.27,1.52,9.27,5.25v2.04h-2.33v-1.37c0-2.33-4.02-3.59-6.87-3.59C6.8,2.33,2.57,6.62,2.57,12.61s4.19,10.68,9.83,10.68c5.11,0,8.1-3.49,8.1-3.49l1.41,1.8s-3.42,4.02-9.55,4.02C5.11,25.62,0,19.84,0,12.61S5.25,0,12.23,0Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M33.86,7.05c5.18,0,9.37,3.95,9.37,9.2s-4.19,9.37-9.34,9.37-9.37-4.02-9.37-9.37,4.19-9.2,9.34-9.2ZM33.9,23.47c3.77,0,6.87-3.1,6.87-7.22s-3.1-7.01-6.87-7.01-6.91,3-6.91,7.01,3.1,7.22,6.91,7.22Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M47.71,10.32c0-.49-.28-.78-.78-.78h-1.48v-2.08h2.47c1.48,0,2.15.6,2.15,2.01v1.2c0,.74-.07,1.34-.07,1.34h.07c.85-2.68,2.82-4.72,5.6-4.72.49,0,.95.11.95.11v2.36s-.42-.07-.88-.07c-2.5,0-4.37,1.97-5.14,4.58-.35,1.13-.49,2.33-.49,3.45v7.47h-2.4v-14.87Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M60.71,10.32c0-.49-.28-.78-.78-.78h-1.48v-2.08h2.47c1.48,0,2.15.6,2.15,2.01v1.2c0,.74-.07,1.34-.07,1.34h.07c.85-2.68,2.82-4.72,5.6-4.72.49,0,.95.11.95.11v2.36s-.42-.07-.88-.07c-2.5,0-4.37,1.97-5.14,4.58-.35,1.13-.49,2.33-.49,3.45v7.47h-2.4v-14.87Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M79.85,7.05c4.9,0,7.44,3.77,7.44,8.18,0,.42-.07,1.2-.07,1.2h-13.6c.07,4.48,3.21,7.05,6.87,7.05,3.24,0,5.32-2.18,5.32-2.18l1.16,1.8s-2.57,2.54-6.59,2.54c-5.25,0-9.27-3.81-9.27-9.27,0-5.81,3.98-9.3,8.74-9.3ZM84.81,14.52c-.14-3.66-2.4-5.46-5-5.46-2.92,0-5.5,1.9-6.1,5.46h11.1Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M101.27,14.27h.99v-.21c0-3.7-1.34-5-4.58-5-.88,0-3.45.25-3.45,1.55v1.13h-2.29v-1.69c0-2.47,4.23-3,5.78-3,5.6,0,6.94,3,6.94,6.8v8.49c0,.53.28.78.78.78h1.48v2.08h-2.43c-1.52,0-2.11-.67-2.11-2.11,0-.78.04-1.3.04-1.3h-.07s-1.44,3.84-5.88,3.84c-2.96,0-6.03-1.73-6.03-5.25,0-5.88,7.79-6.1,10.85-6.1ZM96.83,23.57c3.38,0,5.43-3.52,5.43-6.59v-.81h-1.02c-2.78,0-8.35.07-8.35,4.05,0,1.66,1.3,3.35,3.95,3.35Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M132.47,20.3s2.5,3.03,6.41,3.03c2.71,0,4.9-1.76,4.9-4.4,0-6.13-12.23-4.9-12.23-12.58,0-3.45,3-6.34,7.58-6.34,2.4,0,6.7,1.02,6.7,4.26v1.76h-2.33v-1.16c0-1.48-2.04-2.57-4.33-2.57-3.21,0-5.07,1.87-5.07,3.98,0,5.74,12.23,4.33,12.23,12.51,0,3.7-2.85,6.84-7.51,6.84-5.07,0-7.86-3.52-7.86-3.52l1.52-1.8Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M160.16,14.27h.99v-.21c0-3.7-1.34-5-4.58-5-.88,0-3.45.25-3.45,1.55v1.13h-2.29v-1.69c0-2.47,4.23-3,5.78-3,5.6,0,6.94,3,6.94,6.8v8.49c0,.53.28.78.78.78h1.48v2.08h-2.43c-1.52,0-2.11-.67-2.11-2.11,0-.78.03-1.3.03-1.3h-.07s-1.45,3.84-5.88,3.84c-2.96,0-6.03-1.73-6.03-5.25,0-5.88,7.79-6.1,10.85-6.1ZM155.72,23.57c3.38,0,5.43-3.52,5.43-6.59v-.81h-1.02c-2.78,0-8.35.07-8.35,4.05,0,1.66,1.3,3.35,3.95,3.35Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M169.6,10.32c0-.49-.28-.78-.77-.78h-1.48v-2.08h2.47c1.48,0,2.15.63,2.15,1.97v.81c0,.7-.07,1.27-.07,1.27h.07c.63-1.62,2.89-4.48,7.05-4.48,4.4,0,5.95,2.5,5.95,6.87v8.42c0,.53.28.78.78.78h1.48v2.08h-2.5c-1.51,0-2.15-.63-2.15-2.15v-8.49c0-2.78-.42-5.25-3.84-5.25-3.03,0-5.64,2.08-6.45,5-.21.74-.28,1.55-.28,2.4v8.49h-2.4v-14.87Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M197.12,7.05c4.76,0,5.88,3.03,5.88,3.03h.07v-.67c0-1.27.56-1.94,2.01-1.94h2.47v2.08h-1.45c-.49,0-.77.25-.77.78v14.45c0,5.64-4.16,7.82-8.53,7.82-2.01,0-4.05-.53-5.78-1.41l.92-2.01s2.18,1.23,4.79,1.23c3.45,0,6.2-1.62,6.2-5.57v-1.55c0-.67.03-1.27.03-1.27h-.07c-1.06,1.94-2.82,3.07-5.57,3.07-4.76,0-7.89-3.77-7.89-9.09s2.93-8.95,7.68-8.95ZM202.97,16c0-5.18-2.47-6.77-5.6-6.77-3.45,0-5.46,2.5-5.46,6.73s2.29,6.98,5.78,6.98c2.82,0,5.29-1.73,5.29-6.94Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M211.36,10.32c0-.49-.28-.78-.78-.78h-1.48v-2.08h2.5c1.51,0,2.15.63,2.15,2.08v8.56c0,2.78.46,5.25,3.88,5.25,4.02,0,6.52-3.56,6.52-7.4V7.47h2.4v14.87c0,.53.28.78.78.78h1.48v2.08h-2.43c-1.48,0-2.15-.63-2.15-1.97v-.81c0-.7.07-1.27.07-1.27h-.07c-.7,1.73-3.03,4.48-6.91,4.48-4.19,0-5.95-2.26-5.95-6.87v-8.42Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M232.85,10.32c0-.49-.28-.78-.78-.78h-1.48v-2.08h2.5c1.52,0,2.15.63,2.15,2.15v12.72c0,.53.28.78.78.78h1.48v2.08h-2.5c-1.52,0-2.15-.63-2.15-2.15v-12.72ZM232.78.42h2.33v3.03h-2.33V.42Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M241.52,10.32c0-.49-.28-.78-.77-.78h-1.48v-2.08h2.47c1.48,0,2.15.63,2.15,1.97v.81c0,.7-.07,1.27-.07,1.27h.07c.63-1.62,2.89-4.48,7.05-4.48,4.4,0,5.95,2.5,5.95,6.87v8.42c0,.53.28.78.78.78h1.48v2.08h-2.5c-1.51,0-2.15-.63-2.15-2.15v-8.49c0-2.78-.42-5.25-3.84-5.25-3.03,0-5.64,2.08-6.45,5-.21.74-.28,1.55-.28,2.4v8.49h-2.4v-14.87Z"
        />
        <path
          fill={getColor('#91959e')}
          d="M270.63,7.05c5.18,0,9.37,3.95,9.37,9.2s-4.19,9.37-9.34,9.37-9.37-4.02-9.37-9.37,4.19-9.2,9.34-9.2ZM270.66,23.47c3.77,0,6.87-3.1,6.87-7.22s-3.1-7.01-6.87-7.01-6.91,3-6.91,7.01,3.1,7.22,6.91,7.22Z"
        />
      </g>
      <path
        fill={getColor('#1e120d')}
        d="M113.74,11.84v-.07s-3.42-1.09-3.42-5.5c0-3.66,2.82-6.27,7.33-6.27,1.34,0,2.89.39,2.89.39l-.7,2.01s-1.16-.25-2.04-.25c-2.85,0-4.93,1.69-4.93,4.26,0,1.66.7,4.48,5.32,4.48h5.14v-3.42h2.43v3.42h3.28v2.18h-3.28v3.63c0,5.92-3.21,8.92-8.14,8.92s-8.35-3.17-8.35-7.33c0-2.75,1.55-5.6,4.48-6.45ZM117.62,23.33c3.42,0,5.71-1.94,5.71-6.7v-3.56h-5.07c-4.33,0-6.48,2.04-6.48,5.14,0,2.78,2.15,5.11,5.85,5.11Z"
      />
      <g>
        <path
          fill={getColor('1e120d')}
          d="M167.72,36.81h-1.01v-.97h6.26c.67,0,.95.28.95.95v1.09h-1.03v-.73c0-.23-.12-.34-.34-.34h-3.74v3.99h4.11v.97h-4.11v3.72c0,.23.12.34.34.34h3.85c.22,0,.34-.11.34-.34v-.73h1.01v1.09c0,.67-.28.95-.95.95h-4.73c-.67,0-.95-.28-.95-.95v-9.03Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M175.8,44.87s.87,1.18,2.41,1.18c.87,0,1.65-.42,1.65-1.25,0-1.71-4.39-1.4-4.39-3.92,0-1.42,1.22-2.12,2.79-2.12.79,0,2.38.31,2.38,1.42v.67h-1v-.37c0-.58-.84-.79-1.35-.79-1.09,0-1.74.37-1.74,1.14,0,1.76,4.39,1.36,4.39,3.96,0,1.31-1.18,2.2-2.74,2.2-2.04,0-3.01-1.37-3.01-1.37l.61-.73Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M183.04,39.84h-1.03v-.89h1.04v-2.16h1.04v2.16h1.96v.89h-1.96v3.99c0,1.84,1.17,2.07,1.76,2.07.22,0,.36-.03.36-.03v.95s-.19.03-.45.03c-.9,0-2.73-.3-2.73-2.91v-4.1Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M188.14,40.22c0-.22-.12-.34-.34-.34h-.65v-.92h1.11c.67,0,.95.28.95.92v3.78c0,1.23.2,2.32,1.71,2.32,1.78,0,2.88-1.57,2.88-3.27v-3.75h1.06v6.57c0,.23.12.34.34.34h.65v.92h-1.07c-.65,0-.95-.28-.95-.87v-.36c0-.31.03-.56.03-.56h-.03c-.31.76-1.34,1.98-3.05,1.98-1.85,0-2.63-1-2.63-3.04v-3.72Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M200.37,38.77c1.9,0,2.55,1.46,2.55,1.46h.03s-.03-.26-.03-.59v-2.54c0-.22-.12-.34-.34-.34h-.65v-.92h1.11c.67,0,.95.28.95.95v8.74c0,.23.12.34.34.34h.65v.92h-1.09c-.65,0-.93-.3-.93-.87,0-.3.03-.5.03-.5h-.03s-.64,1.56-2.69,1.56-3.41-1.68-3.41-4.11,1.46-4.1,3.52-4.1ZM202.96,42.86c0-1.59-.81-3.15-2.49-3.15-1.39,0-2.52,1.17-2.52,3.15s1.01,3.16,2.48,3.16c1.31,0,2.54-.93,2.54-3.16Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M206.79,40.22c0-.22-.12-.34-.34-.34h-.65v-.92h1.11c.67,0,.95.28.95.95v5.62c0,.23.12.34.34.34h.65v.92h-1.11c-.67,0-.95-.28-.95-.95v-5.62ZM206.76,35.84h1.03v1.34h-1.03v-1.34Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M214.01,38.77c2.29,0,4.14,1.74,4.14,4.06s-1.85,4.14-4.13,4.14-4.14-1.78-4.14-4.14,1.85-4.06,4.13-4.06ZM214.03,46.03c1.67,0,3.04-1.37,3.04-3.19s-1.37-3.1-3.04-3.1-3.05,1.32-3.05,3.1,1.37,3.19,3.05,3.19Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M223.39,43.28h1.09v.37c0,1.73,1.03,2.29,2.01,2.29s1.98-.58,1.98-2.26v-6.54c0-.23-.12-.34-.34-.34h-2.71v-.97h3.19c.67,0,.95.28.95.95v6.93c0,2.37-1.57,3.25-3.08,3.25s-3.08-.9-3.08-3.25v-.44Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M232.2,40.22c0-.22-.12-.34-.34-.34h-.65v-.92h1.11c.67,0,.95.28.95.92v3.78c0,1.23.2,2.32,1.71,2.32,1.78,0,2.88-1.57,2.88-3.27v-3.75h1.06v6.57c0,.23.12.34.34.34h.65v.92h-1.07c-.65,0-.95-.28-.95-.87v-.36c0-.31.03-.56.03-.56h-.03c-.31.76-1.34,1.98-3.05,1.98-1.85,0-2.63-1-2.63-3.04v-3.72Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M241.62,40.22c0-.22-.12-.34-.34-.34h-.65v-.92h1.09c.65,0,.95.26.95.89v.53c0,.33-.03.59-.03.59h.03c.37-1.18,1.25-2.09,2.48-2.09.22,0,.42.05.42.05v1.04s-.19-.03-.39-.03c-1.11,0-1.93.87-2.27,2.02-.16.5-.22,1.03-.22,1.53v3.3h-1.06v-6.57Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M247.35,40.22c0-.22-.12-.34-.34-.34h-.65v-.92h1.11c.67,0,.95.28.95.95v5.62c0,.23.12.34.34.34h.65v.92h-1.11c-.67,0-.95-.28-.95-.95v-5.62ZM248.38,35.84h1.18l-1.36,1.85h-.92l1.09-1.85Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M254,38.77c1.9,0,2.55,1.46,2.55,1.46h.03s-.03-.26-.03-.59v-2.54c0-.22-.12-.34-.34-.34h-.65v-.92h1.11c.67,0,.95.28.95.95v8.74c0,.23.12.34.34.34h.65v.92h-1.09c-.65,0-.93-.3-.93-.87,0-.3.03-.5.03-.5h-.03s-.64,1.56-2.69,1.56-3.41-1.68-3.41-4.11,1.46-4.1,3.52-4.1ZM256.58,42.86c0-1.59-.81-3.15-2.49-3.15-1.39,0-2.52,1.17-2.52,3.15s1.01,3.16,2.48,3.16c1.31,0,2.54-.93,2.54-3.16Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M260.41,40.22c0-.22-.12-.34-.34-.34h-.65v-.92h1.11c.67,0,.95.28.95.95v5.62c0,.23.12.34.34.34h.65v.92h-1.11c-.67,0-.95-.28-.95-.95v-5.62ZM260.38,35.84h1.03v1.34h-1.03v-1.34Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M267.64,38.77c.89,0,2.79.37,2.79,1.67v.7h-1v-.45c0-.65-1.14-.97-1.79-.97-1.68,0-3.04,1.26-3.04,3.16s1.42,3.13,3.07,3.13,2.52-1.11,2.52-1.11l.5.79s-1.06,1.28-3.07,1.28c-2.34,0-4.11-1.67-4.11-4.1s1.79-4.11,4.13-4.11Z"
        />
        <path
          fill={getColor('1e120d')}
          d="M275.86,38.77c2.29,0,4.14,1.74,4.14,4.06s-1.85,4.14-4.13,4.14-4.14-1.78-4.14-4.14,1.85-4.06,4.13-4.06ZM275.87,46.03c1.67,0,3.04-1.37,3.04-3.19s-1.37-3.1-3.04-3.1-3.05,1.32-3.05,3.1,1.37,3.19,3.05,3.19Z"
        />
      </g>
    </svg>
  );
};

export default CorreaSanguinoLogo;

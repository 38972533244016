import React from 'react';

interface JsLogoProps extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const JsLogo: React.FC<JsLogoProps> = ({ color, ...props }) => (
  <svg
    {...props}
    width="41"
    height="38"
    viewBox="0 0 41 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6015 38C16.1004 38 15.6028 37.8665 15.1635 37.6096L10.5864 34.8575C9.90277 34.4692 10.2364 34.3319 10.4618 34.2528C11.3733 33.9286 11.5583 33.8554 12.5313 33.2942C12.6335 33.235 12.7674 33.2559 12.8721 33.3186L16.3884 35.4378C16.5154 35.5075 16.6955 35.5075 16.8133 35.4378L30.5237 27.4003C30.6507 27.3271 30.7331 27.1772 30.7331 27.0239V10.9558C30.7331 10.7954 30.6507 10.6525 30.521 10.5724L16.816 2.54179C16.689 2.46511 16.5209 2.46511 16.3939 2.54179L2.69164 10.5724C2.55883 10.649 2.47441 10.7989 2.47441 10.9523V27.0204C2.47441 27.1737 2.55677 27.3201 2.68718 27.3933L6.44169 29.5961C8.48024 30.6313 9.72603 29.4114 9.72603 28.1845V12.3256C9.72603 12.099 9.90105 11.9247 10.1241 11.9247H11.8607C12.0769 11.9247 12.2553 12.099 12.2553 12.3256V28.1915C12.2553 30.952 10.7728 32.5379 8.19539 32.5379C7.40262 32.5379 6.77802 32.5379 5.03461 31.6665L1.43797 29.5613C0.549106 29.0385 0 28.0625 0 27.0204V10.9523C0 9.90662 0.549106 8.93069 1.43797 8.41484L15.1622 0.373819C16.0304 -0.124606 17.1836 -0.124606 18.045 0.373819L31.752 8.41832C32.6374 8.93766 33.19 9.91011 33.19 10.9558V27.0239C33.19 28.066 32.6374 29.0385 31.752 29.5613L18.045 37.6023C17.6057 37.8602 17.1081 37.9927 16.6001 37.9927"
      fill={color}
    />
    <path
      d="M21.012 26.8452C14.9409 26.8452 13.668 24.0314 13.668 21.6714C13.668 21.447 13.8472 21.2681 14.0698 21.2681H15.862C16.06 21.2681 16.2267 21.4137 16.2579 21.6111C16.5288 23.4549 17.3346 24.3849 21.0058 24.3849C23.9302 24.3849 25.1736 23.7179 25.1736 22.1522C25.1736 21.251 24.8193 20.5812 20.2764 20.1323C16.4767 19.7536 14.1289 18.9085 14.1289 15.8401C14.1289 13.0137 16.4906 11.3305 20.4466 11.3305C24.8888 11.3305 27.0908 12.8874 27.3686 16.2328C27.3791 16.3486 27.3374 16.4608 27.261 16.5449C27.1846 16.6256 27.0769 16.6747 26.9657 16.6747H25.1632C24.9756 16.6747 24.8124 16.5414 24.7742 16.3591C24.34 14.4199 23.2911 13.7992 20.4396 13.7992C17.2478 13.7992 16.8761 14.9213 16.8761 15.7629C16.8761 16.7834 17.3138 17.0815 21.6205 17.6566C25.8856 18.2282 27.9105 19.0347 27.9105 22.061C27.9105 25.1119 25.3889 26.8617 20.9919 26.8617M37.8924 9.71736H38.3578C38.7398 9.71736 38.8128 9.44734 38.8128 9.28954C38.8128 8.87574 38.5315 8.87575 38.3752 8.87575H37.8959L37.8924 9.71736ZM37.3263 8.39182H38.3578C38.712 8.39182 39.4067 8.39182 39.4067 9.19135C39.4067 9.74892 39.0524 9.86464 38.8406 9.93477C39.2539 9.96283 39.2817 10.2364 39.3372 10.6221C39.365 10.8641 39.4102 11.2814 39.4935 11.4216H38.8579C38.8406 11.2814 38.7433 10.5099 38.7433 10.4678C38.7016 10.296 38.6426 10.2118 38.4307 10.2118H37.9063V11.4251H37.3263V8.39182ZM36.0863 9.89971C36.0863 11.1551 37.0901 12.1721 38.3231 12.1721C39.5665 12.1721 40.5702 11.1341 40.5702 9.89971C40.5702 8.64079 39.5526 7.64138 38.3196 7.64138C37.104 7.64138 36.0829 8.62676 36.0829 9.8962M41.0009 9.90672C41.0009 11.3936 39.7957 12.6069 38.3265 12.6069C36.8678 12.6069 35.6522 11.4076 35.6522 9.90672C35.6522 8.37779 36.8956 7.20654 38.3265 7.20654C39.7679 7.20654 40.9974 8.38129 40.9974 9.90672"
      fill={color}
    />
  </svg>
);

export default JsLogo;

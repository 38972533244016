import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import Landing from './components/Landing';
import { MenuProvider } from './contexts/MenuContext';

function App() {
  return (
    <MenuProvider>
      <div>
        <Navbar />
        <Landing />
      </div>
    </MenuProvider>
  );
}

export default App;

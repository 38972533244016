import React from 'react';

interface AlisurLogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'original' | 'black' | 'white';
}

const AlisurLogo: React.FC<AlisurLogoProps> = ({
  variant = 'original',
  ...props
}) => {
  const getColor = (defaultColor: string) => {
    switch (variant) {
      case 'black':
        return '#3D3D3D';
      case 'white':
        return '#E8E8ED';
      default:
        return defaultColor;
    }
  };

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 280 73.1" {...props}>
      <defs>
        <style>
          {`
                        .st0 { fill: ${getColor('#58585b')}; }
                        .st1 { fill: ${getColor('#4fb862')}; }
                        .st2 { fill: ${getColor('#98cb63')}; }
                        .st3 { fill: ${getColor('#418270')}; }
                        .st4 { fill: ${getColor('#639a77')}; }
                        .st5 { fill: ${getColor('#536d33')}; }
                    `}
        </style>
      </defs>
      <g id="Home-Feedback">
        <g id="Material_x2F_Top-Navigation-Alter">
          <g id="Branding_x2F_Logo-Color">
            <g id="Alisur">
              <path
                id="Fill-1"
                fill={getColor('#58585b')}
                d="M92.8,28.2l-4.9,12h9.8l-5-12ZM84.5,48.5l-1.3,3.1-.4.9c-1,2.3-2.6,3.4-4.6,3.4s-2.4-.4-3.3-1.3c-.9-.9-1.4-2-1.4-3.2s.2-1.7.7-2.7l.4-.9,13.6-31c.9-2,2.4-2.9,4.5-2.9s1.9.3,2.7.8c.8.5,1.4,1.2,1.8,2.2l14,30.9c.5,1,.7,1.7.9,2.2.1.5.2,1,.2,1.6,0,1.3-.5,2.3-1.4,3.3-.9.9-2.1,1.4-3.3,1.4-2.1,0-3.6-1.1-4.6-3.3l-.4-1-1.4-3.1h-16.5Z"
              />
              <path
                id="Fill-4"
                fill={getColor('#58585b')}
                d="M115.9,19.5v-.9c0-1.4.4-2.5,1.2-3.4.7-.8,1.7-1.3,3-1.3s2.2.4,3,1.3c.7.9,1.1,2,1.1,3.4v32.6c0,1.6-.3,2.8-1,3.7-.7.9-1.7,1.3-3,1.3s-2.2-.4-3-1.3c-.8-.9-1.1-2-1.1-3.4V19.5Z"
              />
              <path
                id="Fill-6"
                fill={getColor('#58585b')}
                d="M139.7,50.5v.9c0,1.4-.4,2.5-1.2,3.4-.7.8-1.7,1.3-2.9,1.3s-2.2-.4-3-1.3c-.8-.9-1.1-2-1.1-3.4v-21.2c0-1.4.4-2.5,1.2-3.4.7-.8,1.7-1.3,2.9-1.3s2.2.4,3,1.3c.8.9,1.1,2,1.1,3.4v20.3ZM135.6,13.8c1.4,0,2.5.5,3.4,1.4.9,1,1.4,2.1,1.4,3.5s-.5,2.6-1.4,3.5c-1,1-2.1,1.5-3.5,1.5s-1.3-.1-1.9-.4c-.6-.3-1.1-.6-1.6-1.1-.5-.4-.8-.9-1.1-1.5-.3-.6-.4-1.2-.4-1.9,0-1.5.5-2.7,1.4-3.6,1-1,2.2-1.4,3.6-1.4Z"
              />
              <path
                id="Fill-8"
                fill={getColor('#58585b')}
                d="M157.2,25.4c1.3,0,2.6.2,3.8.5,1.2.3,2.2.7,3.1,1.3.9.5,1.6,1.2,2.1,1.9.5.7.8,1.5.8,2.3s-.4,1.8-1.1,2.6c-.7.8-1.6,1.1-2.6,1.1s-1.1,0-1.6-.3c-.5-.2-1.2-.5-1.9-1-1-.6-1.7-1-2.1-1.2-.4-.2-.8-.3-1.3-.3s-.8.1-1.1.4c-.3.3-.4.6-.4,1,0,.7.3,1.3,1,1.7.7.4,2,.9,3.9,1.4,1.4.4,2.5.7,3.2,1,.7.3,1.4.6,2.1.9,1.3.7,2.3,1.7,3,3,.7,1.3,1,2.7,1,4.3s-.3,3-.9,4.2c-.6,1.3-1.4,2.4-2.4,3.3-1,.9-2.3,1.6-3.8,2.1-1.5.5-3,.8-4.8.8s-3.1-.2-4.6-.7c-1.5-.5-2.8-1.1-3.9-1.8-1.1-.8-2.1-1.6-2.7-2.6-.7-1-1-2-1-3.1s.4-2.1,1.1-3c.7-.9,1.6-1.3,2.6-1.3s1.4.2,2.1.5c.7.4,1.4.9,2.2,1.6.6.5,1.1,1,1.6,1.3.4.3.8.6,1.2.8.3.2.7.3,1,.4.3,0,.7,0,1.1,0,.8,0,1.5-.2,2-.6.5-.4.8-.9.8-1.6,0-1-.9-1.7-2.7-2.1-1.1-.3-2.4-.7-3.8-1.1-1.4-.5-2.5-.9-3.3-1.3-1.4-.7-2.4-1.7-3.3-3.1-.8-1.3-1.2-2.8-1.2-4.3s.5-3.4,1.6-4.9c1-1.5,2.5-2.6,4.2-3.3,1.4-.5,3.1-.8,5-.8"
              />
              <path
                id="Fill-10"
                fill={getColor('#58585b')}
                d="M182.5,40.8c0,1.3,0,2.2.1,2.7,0,.5.2,1.1.4,1.6.4.8,1,1.5,1.8,2.1.8.6,1.7.8,2.7.8,1.4,0,2.6-.5,3.6-1.6.9-1,1.4-2.3,1.4-3.8v-12.3c0-1.4.4-2.5,1.2-3.4.7-.8,1.7-1.3,3-1.3s2.2.4,2.9,1.3c.8.9,1.1,2,1.1,3.4v11.5c0,2-.2,3.8-.7,5.3-.5,1.5-1.2,2.9-2.2,4.2-1.2,1.6-2.7,2.8-4.6,3.7-1.8.9-3.7,1.3-5.7,1.3s-4.5-.6-6.6-1.8c-2.1-1.2-3.7-2.8-5-4.9-.6-1-1-2.1-1.3-3.6-.3-1.4-.5-2.9-.5-4.6v-11.3c0-1.4.4-2.5,1.1-3.4.7-.8,1.7-1.3,2.9-1.3s2.2.4,3,1.3c.8.9,1.1,2,1.1,3.4v10.5Z"
              />
              <path
                id="Fill-12"
                fill={getColor('#58585b')}
                d="M215.7,28.1c2-1.8,4.1-2.6,6.3-2.6s1.4.1,2.1.4c.6.3,1.2.6,1.7,1.1.5.5.9,1,1.1,1.6.3.6.4,1.3.4,2,0,1.4-.5,2.5-1.4,3.5-1,1-2.1,1.4-3.5,1.4s-1,0-1.3-.2c-.3-.1-.9-.4-1.6-.9-.6-.4-1.1-.6-1.6-.6-.7,0-1.1.3-1.4.9-.3.6-.4,1.6-.4,2.9v13.8c0,1.4-.4,2.5-1.1,3.4-.7.8-1.7,1.3-2.9,1.3s-2.2-.4-3-1.3c-.8-.9-1.1-2-1.1-3.4v-19.8c0-.6,0-1.1,0-1.5,0-.4,0-.7,0-1,0-.3,0-.5.2-.7,0-.2.2-.4.3-.6.8-1.5,2-2.2,3.5-2.2s2.9.8,3.8,2.4"
              />
              <path
                id="Fill-14"
                fill={getColor('#58585b')}
                d="M229.5,56.5c-1.5,0-2.7-.5-3.7-1.6-1-1.1-1.5-2.3-1.5-3.8s.5-2.8,1.5-3.8c1-1,2.3-1.6,3.7-1.6s2.7.5,3.8,1.6c1,1,1.6,2.3,1.6,3.8s-.5,2.8-1.6,3.8c-1,1.1-2.3,1.6-3.8,1.6"
              />
              <path
                id="Fill-16"
                fill={getColor('#58585b')}
                d="M239.8,41c0-2.1.4-4.1,1.2-5.9.8-1.9,1.9-3.5,3.3-4.9,1.4-1.4,3-2.5,4.8-3.3,1.8-.8,3.8-1.2,5.8-1.2s3.1.2,4.6.7c1.5.5,2.9,1.2,4.2,2,1.1.8,1.9,1.5,2.3,2.1.4.6.6,1.4.6,2.3s-.4,2.1-1.2,2.9c-.8.8-1.8,1.2-3,1.2s-.9,0-1.2-.2c-.3-.1-.8-.4-1.4-.9-.9-.7-1.7-1.2-2.4-1.5-.7-.3-1.4-.4-2.3-.4s-1.9.2-2.8.6c-.9.4-1.6.9-2.3,1.5-.6.7-1.1,1.4-1.5,2.3-.4.9-.6,1.8-.6,2.7s.2,1.9.6,2.8c.4.9.9,1.6,1.5,2.2.6.6,1.4,1.1,2.2,1.5.8.4,1.8.6,2.7.6s2.3-.3,3.1-.8c.1,0,.4-.2.7-.5.3-.2.8-.6,1.4-1,.4-.3.9-.6,1.3-.7.4-.1.8-.2,1.3-.2,1.1,0,2.1.4,2.9,1.2.8.8,1.2,1.8,1.2,3s-.3,1.8-1,2.7c-.7.9-1.6,1.7-2.7,2.4-1.1.7-2.4,1.3-3.8,1.7-1.4.4-2.9.7-4.3.7-2.1,0-4.1-.4-5.9-1.2-1.9-.8-3.5-1.9-4.9-3.3-1.4-1.4-2.5-3.1-3.3-4.9-.8-1.9-1.2-3.9-1.2-6"
              />
              <path
                id="Fill-18"
                fill={getColor('#58585b')}
                d="M271.8,19.8v-.9c0-1.4.4-2.5,1.2-3.4.7-.8,1.7-1.2,2.9-1.2s2.2.4,2.9,1.3c.7.9,1.1,2,1.1,3.3v32.5c0,1.6-.3,2.8-1,3.7-.7.9-1.7,1.3-3,1.3s-2.2-.4-3-1.3c-.8-.9-1.1-2-1.1-3.3v-31.9Z"
              />
            </g>
            <g id="Iso">
              <path
                id="Fill-11"
                data-name="Fill-1"
                fill={getColor('#639a77')}
                d="M3.4,45.4c-1.9,0-3.4-1.6-3.4-3.5v-15.9c0-1.9,1.5-3.5,3.4-3.5s3.4,1.6,3.4,3.5v15.9c0,1.9-1.5,3.5-3.4,3.5"
              />
              <path
                id="Fill-3"
                fill={getColor('#4fb862')}
                d="M14.9,48.2c-1.9,0-3.4-1.5-3.4-3.3V10.2c0-1.8,1.5-3.3,3.4-3.3s3.4,1.5,3.4,3.3v34.7c0,1.8-1.5,3.3-3.4,3.3"
              />
              <path
                id="Fill-5"
                fill={getColor('#98cb63')}
                d="M27.6,52.4c-1.9,0-3.4-1.5-3.4-3.4V7.5c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4v41.5c0,1.9-1.5,3.4-3.4,3.4"
              />
              <path
                id="Fill-7"
                fill={getColor('#536d33')}
                d="M40.2,45.9c-1.9,0-3.4-1.6-3.4-3.6V3.6c0-2,1.5-3.6,3.4-3.6s3.4,1.6,3.4,3.6v38.8c0,2-1.5,3.6-3.4,3.6"
              />
              <path
                id="Fill-101"
                data-name="Fill-10"
                fill={getColor('#418270')}
                d="M51.7,50.1c-1.9,0-3.4-1.5-3.4-3.4v-25.4c0-1.9,1.5-3.4,3.4-3.4s3.4,1.5,3.4,3.4v25.4c0,1.9-1.5,3.4-3.4,3.4"
              />
              <path
                id="Fill-121"
                data-name="Fill-12"
                fill={getColor('#58585b')}
                d="M28.6,73.1c-15.6,0-27.4-10.5-27.6-10.7-1.3-1.1-1.4-3.1-.2-4.4,1.1-1.3,3.1-1.4,4.4-.2,1,.9,22.8,20.1,44.8,0,1.3-1.1,3.2-1.1,4.4.2,1.1,1.3,1,3.2-.2,4.4-8.8,8-17.6,10.7-25.5,10.7"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default AlisurLogo;

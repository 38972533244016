import React from 'react';

interface C3DLogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'original' | 'black' | 'white';
}

const C3DLogo: React.FC<C3DLogoProps> = ({
  variant = 'original',
  ...props
}) => {
  const getColor = (defaultColor: string) => {
    switch (variant) {
      case 'black':
        return '#3D3D3D';
      case 'white':
        return '#E8E8ED';
      default:
        return defaultColor;
    }
  };

  return (
    <svg
      {...props}
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 280 110.78"
    >
      <path
        fill={getColor('#1129ea')}
        d="M55.59.27l-.12,11.16C21.62,12.08,1.34,46.7,16.82,76.83s59.1,30.71,73.76,1.26l10.21,5.18c-12.71,29.26-54.33,35.34-78.93,16.71C-20.25,68.06,2.55-.27,55.59.27Z"
      />
      <polygon
        fill={getColor('#1129ea')}
        points="178.51 .27 177.66 13.51 154.6 46.27 140.18 45.95 163.85 11.55 115.36 11.55 115.36 .27 178.51 .27"
      />
      <path
        fill={getColor('#1129ea')}
        d="M180.62,61.31c13.31,34.03-19.59,58.93-50.91,45.84-6.34-2.65-15.76-10.53-17.7-17.27-.61-2.13.32-1.48,1.64-1.65,12.81-1.69,10.47,2.96,18.74,7.22,24.24,12.47,51.57-10,33.72-34.27l14.51.13Z"
      />
      <path
        fill={getColor('#1129ea')}
        d="M230.95,99.51c20.26-2.05,35.15-18.4,36.66-38.34h12.4c-2.38,21.23-16,42.05-37.44,47.7-1.45.38-7.5,1.92-8.23,1.92h-26.5v-11.28c7.45-.54,15.79.74,23.12,0Z"
      />
      <path
        fill={getColor('#1129ea')}
        d="M234.33.27c23.84,2.52,41.88,23.33,45.67,46.22l-12.29-.1c-2.44-13.65-11.48-27.27-24.95-32-2.49-.87-9.67-2.84-11.82-2.84h-21.99V.27c8.14.62,17.38-.85,25.37,0Z"
      />
    </svg>
  );
};

export default C3DLogo;

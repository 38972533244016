import React from 'react';

interface ShieldIconProps extends React.SVGProps<SVGSVGElement> {
  isHovered?: boolean;
}

const ShieldIcon: React.FC<ShieldIconProps> = ({
  isHovered = false,
  ...props
}) => {
  const getColor = (defaultColor: string) => {
    return isHovered ? '#FFFFFF' : defaultColor;
  };

  return (
    <svg
      {...props}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 0.976002L0.923996 3.215L0.999996 4.09C1.107 5.265 2.139 15.63 5.441 17.832C7.08572 18.9503 8.79246 19.9746 10.553 20.9L11 21.123L11.447 20.9C13.2061 19.9761 14.9114 18.9535 16.555 17.837C19.857 15.63 20.889 5.265 21 4.09L21.08 3.215L11 0.976002ZM18.263 9H12V3.247L18.915 4.783C18.771 6.067 18.551 7.542 18.263 9ZM10 3.247V9H3.737C3.449 7.542 3.229 6.067 3.085 4.783L10 3.247ZM4.184 11H10V18.311C9.147 17.82 7.965 17.111 6.555 16.168C5.6 15.529 4.793 13.436 4.184 11ZM15.445 16.168C14.035 17.108 12.853 17.82 12 18.311V11H17.816C17.207 13.436 16.4 15.529 15.445 16.168Z"
        fill={getColor('#5D5D5D')}
      />
    </svg>
  );
};

export default ShieldIcon;

import React, { useEffect, useState } from 'react';

import AWSLogo from '../assets/technologies/AWSLogo';
import ReactLogo from '../assets/technologies/ReactLogo';
import PythonLogo from '../assets/technologies/PythonLogo';
import JsLogo from '../assets/technologies/JsLogo';
import GitHubLogo from '../assets/technologies/GitHubLogo';

import C3DExample from '../assets/examples/C3D.png';
import SanGeronimoExample from '../assets/examples/SanGeronimo.png';
import NuevaAgricolaExample from '../assets/examples/NuevaAgricola.png';
import CorreaSanguinoExample from '../assets/examples/CorreaSanguino.png';
import AlisurExample from '../assets/examples/Alisur.png';

import C3DLogo from '../assets/clients/C3DLogo';
import SanGeronimoLogo from '../assets/clients/SanGeronimoLogo';
import AlisurLogo from '../assets/clients/AlisurLogo';
import NuevaAgricolaLogo from '../assets/clients/NuevaAgricolaLogo';
import CorreaSanguinoLogo from '../assets/clients/CorreaSanguinoLogo';



type Company = {
  logo: JSX.Element;
  logoStyle: string;
  name: string;
  description: string;
  bgColor: string;
  example: string;
};

const technologies = [
  { component: AWSLogo, name: 'AWS' },
  { component: ReactLogo, name: 'React' },
  { component: PythonLogo, name: 'Python' },
  { component: JsLogo, name: 'JavaScript' },
  { component: GitHubLogo, name: 'GitHub' },
];

const Clients: React.FC = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isFading, setIsFading] = useState(false);
  const [hoveredTech, setHoveredTech] = useState<string | null>(null);
  const [parallaxOffset, setParallaxOffset] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const interval = setInterval(() => {
      setIsFading(true);
      setTimeout(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % companies.length);
        setIsFading(false);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleMouseMove = (e: React.MouseEvent) => {
    const { clientX, clientY, currentTarget } = e;
    const { left, top, width, height } = currentTarget.getBoundingClientRect();

    const offsetX = ((clientX - left) / width - 0.5) * 70;
    const offsetY = ((clientY - top) / height - 0.5) * 70;
    setParallaxOffset({ x: offsetX, y: offsetY });
  };

  const handleMouseLeave = () => {
    setParallaxOffset({ x: 0, y: 0 });
  };

  const companies: Company[] = [
    {
      logo: (
        <C3DLogo
          className={`h-8 object-contain transition-opacity duration-700 ease-in-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
        />
      ),
      logoStyle:
        'hidden md:block absolute -right-[400px] top-0 h-screen mx-auto transform transition-transform duration-300',
      name: 'C3D',
      description:
        'Gestión de sensores, actuadores y visualización de datos para empresas, todo en el mismo lugar.',
      bgColor: 'bg-blue',
      example: C3DExample,
    },
    {
      logo: (
        <SanGeronimoLogo
          className={`h-8 object-contain transition-opacity duration-700 ease-in-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
        />
      ),
      logoStyle:
        'hidden md:block absolute -right-[300px] top-0 h-screen mx-auto transform transition-transform duration-300',
      name: 'San Gerónimo',
      description:
        'Soporte de emergencia, asesorías y desarrollo de sistemas conectados al SII.',
      bgColor: 'bg-yellow',
      example: SanGeronimoExample,
    },
    {
      logo: (
        <NuevaAgricolaLogo
          className={`h-11 object-contain transition-opacity duration-700 ease-in-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
        />
      ),
      logoStyle:
        'hidden md:block absolute -right-[300px] top-0 h-screen mx-auto transform transition-transform duration-300',
      name: 'Nueva Agrícola',
      description:
        'Gestión de insumos, visualizador de cargas de trabajo e informes de rendimiento para campos.',
      bgColor: 'bg-red',
      example: NuevaAgricolaExample,
    },
    {
      logo: (
        <CorreaSanguinoLogo
          className={`h-8 object-contain transition-opacity duration-700 ease-in-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
        />
      ),
      logoStyle:
        'hidden md:block absolute -right-[180px] top-0 h-screen mx-auto transform transition-transform duration-300',
      name: 'Correo Sanguino',
      description:
        'Automatización de flujos legales, aumentando la eficiencia y el volumen de causas a procesar.',
      bgColor: 'bg-blue',
      example: CorreaSanguinoExample,
    },
    {
      logo: (
        <AlisurLogo
          className={`h-8 object-contain transition-opacity duration-700 ease-in-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
        />
      ),
      logoStyle:
        'hidden md:block absolute -right-[420px] top-0 h-screen mx-auto transform transition-transform duration-300',
      name: 'Alisur',
      description:
        'Gestión de flujos de insumos complejos, con generación de seguimiento, órdenes de compras y modernos sistemas de interacción con clientes y proveedores.',
      bgColor: 'bg-green',
      example: AlisurExample,
    },
  ];

  return (
    <section className="relative w-full min-h-[1020px] h-full z-0 flex flex-col items-center bg-lightGray overflow-hidden mt-20 py-10">
      <div
        className={`absolute right-0 top-0 w-full h-screen ${companies[activeIndex].bgColor} transition-colors duration-700`}
      ></div>

      {/* Mobile View */}
      <div className="absolute md:hidden w-full flex flex-col items-center">
        <img
          src={companies[activeIndex].example}
          className="h-80 max-w-full mx-auto"
          alt={`${companies[activeIndex].name} Example`}
        />

        <div className="relative w-full flex flex-col justify-start items-start space-y-10 p-10 bg-white">
          {companies[activeIndex].logo}
          <p
            className={`text-black text-4xl font-medium transition-opacity duration-700 ease-in-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
          >
          
            {companies[activeIndex].description}
          </p>
          <div className="flex space-x-3 w-full">
            {companies.map((_, index) => (
              <div key={index} className="h-1 w-full bg-gray overflow-hidden">
                <div
                  className={`h-full ${index === activeIndex ? 'bg-black' : 'bg-transparent'} transition-all`}
                  style={{
                    width: index === activeIndex ? '100%' : '0%',
                    transition:
                      index === activeIndex ? 'width 5s linear' : 'none',
                  }}
                ></div>
              </div>
            ))}
          </div>
          {/* Logo container for mobile */}
            
        </div>
        <div className="fixed flex left-0 w-screen bg-black justify-start items-center py-10 space-x-9 px-10">
            {technologies.map(({ component: LogoComponent, name }) => (
              <LogoComponent
                key={name}
                color={hoveredTech === name ? '#FCFCFD' : '#3D3D3D'}
                onMouseEnter={() => setHoveredTech(name)}
                onMouseLeave={() => setHoveredTech(null)}
                className="h-10"
              />
            ))}
          </div>
      </div>

      {/* Desktop View */}
      <div className="hidden md:flex absolute left-0 top-0 w-5/12 h-[700px]"></div>
      <img
        src={companies[activeIndex].example}
        className={companies[activeIndex].logoStyle}
        style={{
          transform: `translate(${parallaxOffset.x}px, ${parallaxOffset.y}px)`,
        }}
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        alt={`${companies[activeIndex].name} Example`}
      />

      <div className="hidden md:flex absolute left-0 bottom-32 bg-white w-1/2 h-[720px] flex-col justify-center items-start px-14 space-y-24">
        {companies[activeIndex].logo}
        <p
          className={`text-black text-5xl font-medium transition-opacity duration-700 ease-in-out ${isFading ? 'opacity-0' : 'opacity-100'}`}
        >
          {companies[activeIndex].description}
        </p>
        <div className="flex space-x-3 w-[400px]">
          {companies.map((_, index) => (
            <div key={index} className="h-1 w-full bg-gray overflow-hidden">
              <div
                className={`h-full ${index === activeIndex ? 'bg-black' : 'bg-transparent'} transition-all`}
                style={{
                  width: index === activeIndex ? '100%' : '0%',
                  transition:
                    index === activeIndex ? 'width 5s linear' : 'none',
                }}
              ></div>
            </div>
          ))}
        </div>
          
      </div>

      <div className="hidden md:flex absolute left-0 bottom-0 bg-black flex justify-center items-center w-[900px] py-10 space-x-24 px-10">
        {technologies.map(({ component: LogoComponent, name }) => (
          <LogoComponent
          key={name}
          color={hoveredTech === name ? '#FCFCFD' : '#3D3D3D'}
          onMouseEnter={() => setHoveredTech(name)}
          onMouseLeave={() => setHoveredTech(null)}
          className="h-10"
          />
        ))}
      </div>
      

        <div className="h-20 bg-[#050505]"></div>
    </section>
  );
};

export default Clients;

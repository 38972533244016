import React from 'react';

interface MenuIconProps extends React.SVGProps<SVGSVGElement> {
  isWhiteBackground: boolean;
}

const MenuIcon: React.FC<MenuIconProps> = ({ isWhiteBackground, ...props }) => (
  <svg
    {...props}
    width="23"
    height="22"
    viewBox="0 0 24 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.00002 21.5L24 21.5L24 18.5L9.00002 18.5L9.00002 21.5Z"
      fill={isWhiteBackground ? 'black' : 'white'}
    />
    <path
      d="M2.67029e-05 12.5L24 12.5L24 9.5L2.64406e-05 9.50001L2.67029e-05 12.5Z"
      fill={isWhiteBackground ? 'black' : 'white'}
    />
    <path
      d="M9.00002 3.50002L24 3.50002L24 0.500019L9.00002 0.50002L9.00002 3.50002Z"
      fill={isWhiteBackground ? 'black' : 'white'}
    />
  </svg>
);

export default MenuIcon;

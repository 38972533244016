const colors = {
  primary: '#ea8f0e',
  red: '#f20c28',
  blue: '#0043f0',
  green: '#0eea4b',
  black: '#0d0d0d',
  gray: '#e2e2e9',
  lightGray: '#FCFCFD',
  white: '#FFFFFF',
  yellow: '#EA8F0E',
} as const;

export type Colors = typeof colors;
export default colors;

import React from 'react';
import PlusIcon from './icons/PlusIcon';
import MinusIcon from './icons/MinusIcon';

interface Question {
  question: string;
  answer: string;
}

const questions: Question[] = [
  {
    question: '¿Cómo podemos ver si somos un buen match?',
    answer:
      'Podemos comenzar con una reunión inicial para comprender tus necesidades y objetivos, mientras te contamos más sobre nuestro enfoque y metodología. Este proceso nos permite evaluar si compartimos una visión alineada y si nuestras habilidades son las adecuadas para tu proyecto.',
  },
  {
    question: '¿Qué pasa si el servicio que necesito no está dentro de los que comentan?',
    answer:
      'Siempre estamos abiertos a explorar nuevas áreas. Si el servicio que necesitas no aparece en nuestra lista, contáctanos igualmente. Evaluaremos tus necesidades y te daremos una respuesta honesta sobre si podemos ayudarte o, en su defecto, recomendarte algún otro proveedor.',
  },
  {
    question: '¿A qué se refieren con ser un equipo cercano con el cliente?',
    answer:
      'Nos gusta trabajar de manera colaborativa y mantener una comunicación fluida durante todo el proceso. Esto significa que tendrás acceso directo a nuestro equipo, y trabajaremos contigo para asegurar que cada decisión se tome en función de tus objetivos y expectativas.',
  },
];

const Faq: React.FC = () => {
  const [openIndex, setOpenIndex] = React.useState<number | null>(null);

  const toggleAnswer = (index: number) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <section className="relative w-full h-screen z-0 flex flex-col md:flex md:flex-row md:justify-center md:items-start bg-lightGray md:space-x-20">
      <div className="flex flex-col w-full md:w-auto">
        <span className="text-3xl text-center md:text-left text-black mt-20 md:mt-40">
          PREGUNTAS
        </span>
        <span className="text-4xl md:text-5xl italic font-black text-center md:text-left text-black">
          FRECUENTES
        </span>
      </div>
      <div className="flex flex-col w-50 md:w-2/3 mx-5 md:mx-0 mt-14 md:mt-36 space-y-4 md:space-y-10 h-2/3 max-h-2/3 overflow-y-auto overflow-x-hidden">
        {questions.map((item, index) => (
          <div key={index} className="flex flex-col relative">
            <div
              className="flex justify-between items-center min-h-[5vh] py-3 px-4 md:py-5 md:px-7 cursor-pointer relative z-10"
              onClick={() => toggleAnswer(index)}
            >
              <span
                className={`font-medium text-2xl md:text-3xl text-left w-[85%] md:w-2/3 transition-colors duration-500 ${openIndex === index ? 'text-white' : 'text-black'}`}
              >
                {item.question}
              </span>
              {openIndex === index ? (
                <MinusIcon className="w-5 h-5 text-white transition-colors duration-500" />
              ) : (
                <PlusIcon className="w-5 h-5 text-black transition-colors duration-500" />
              )}
              <div
                className={`absolute top-0 right-0 h-full bg-black transition-transform duration-500 ease-in-out ${
                  openIndex === index
                    ? 'transform translate-x-0'
                    : 'transform translate-x-full'
                }`}
                style={{ width: '100%', zIndex: -1 }}
              />
            </div>
            {openIndex === index && (
              <span className="text-lg md:text-xl text-left text-[#5D5D5D] pl-5 pr-5 md:pl-28 md:pr-96 mt-6 md:mt-12 transition-all duration-300 ease-in-out z-10">
                {item.answer}
              </span>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default Faq;

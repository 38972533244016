import React from 'react';

const HappyIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433284 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34873 18.9426 4.80688 17.0679 2.93215C15.1931 1.05742 12.6513 0.0029116 10 0ZM10 18C8.41775 18 6.87104 17.5308 5.55544 16.6518C4.23985 15.7727 3.21447 14.5233 2.60897 13.0615C2.00347 11.5997 1.84504 9.99113 2.15372 8.43928C2.4624 6.88743 3.22433 5.46197 4.34315 4.34315C5.46197 3.22433 6.88743 2.4624 8.43928 2.15372C9.99113 1.84504 11.5997 2.00346 13.0615 2.60896C14.5233 3.21447 15.7727 4.23984 16.6518 5.55544C17.5308 6.87103 18 8.41775 18 10C17.9976 12.121 17.154 14.1544 15.6542 15.6542C14.1545 17.154 12.121 17.9976 10 18Z"
      fill="white"
    />
    <path
      d="M12 11C12 11.5304 11.7893 12.0391 11.4142 12.4142C11.0391 12.7893 10.5304 13 10 13C9.46957 13 8.96086 12.7893 8.58579 12.4142C8.21071 12.0391 8 11.5304 8 11V10H6V11C6 12.0609 6.42143 13.0783 7.17157 13.8284C7.92172 14.5786 8.93913 15 10 15C11.0609 15 12.0783 14.5786 12.8284 13.8284C13.5786 13.0783 14 12.0609 14 11V10H12V11Z"
      fill="white"
    />
    <path d="M8 6H6V8H8V6Z" fill="white" />
    <path d="M14 6H12V8H14V6Z" fill="white" />
  </svg>
);

export default HappyIcon;

import React from 'react';

interface GitHubLogoProps extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const GitHubLogo: React.FC<GitHubLogoProps> = ({ color, ...props }) => (
  <svg
    {...props}
    width="39"
    height="38"
    viewBox="0 0 39 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.5008 0C8.73244 0 0 8.72075 0 19.4801C0 28.0882 5.58776 35.3899 13.3351 37.9652C14.3092 38.1451 14.6679 37.5427 14.6679 37.028C14.6679 36.5633 14.6491 35.0285 14.6412 33.4014C9.21636 34.5795 8.07156 31.1031 8.07156 31.1031C7.18516 28.8517 5.90567 28.2525 5.90567 28.2525C4.13601 27.0431 6.03879 27.0681 6.03879 27.0681C7.99639 27.2058 9.02843 29.0754 9.02843 29.0754C10.7683 32.0543 13.5904 31.1923 14.7023 30.6947C14.8777 29.4353 15.382 28.5764 15.9411 28.0898C11.6093 27.597 7.05674 25.926 7.05674 18.4616C7.05674 16.3354 7.81785 14.5972 9.06602 13.2329C8.86399 12.7432 8.19684 10.7625 9.25551 8.07929C9.25551 8.07929 10.8936 7.55517 14.6193 10.0756C16.1744 9.64384 17.8423 9.42793 19.5008 9.42011C21.1577 9.42793 22.8271 9.64384 24.3854 10.0756C28.1079 7.55517 29.7429 8.07929 29.7429 8.07929C30.8047 10.7625 30.136 12.7448 29.934 13.2345C31.1837 14.5987 31.9401 16.3369 31.9401 18.4632C31.9401 25.9448 27.3781 27.5938 23.0354 28.0757C23.7355 28.6796 24.3588 29.8655 24.3588 31.6835C24.3588 34.2901 24.3368 36.3881 24.3368 37.0296C24.3368 37.5474 24.6876 38.156 25.6758 37.9636C33.4201 35.3852 39 28.0851 39 19.4801C39 8.72075 30.2691 0 19.5008 0Z"
      fill={color}
    />
  </svg>
);

export default GitHubLogo;

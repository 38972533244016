import React from 'react';

interface ArrowIconProps extends React.SVGProps<SVGSVGElement> {
  isHovered?: boolean;
}

const ArrowIcon: React.FC<ArrowIconProps> = ({
  isHovered = false,
  ...props
}) => {
  const getColor = (defaultColor: string) => {
    return isHovered ? '#FFFFFF' : defaultColor;
  };

  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.46102 21L10.3096 19.1026L7.31186 16.0256H22.4588V13.3419H1L8.46102 21Z"
        fill={getColor('#5D5D5D')}
      />
      <path
        d="M15.539 3L13.6904 4.89742L16.6881 7.97435H1.54124V10.6581H23L15.539 3Z"
        fill={getColor('#5D5D5D')}
      />
    </svg>
  );
};

export default ArrowIcon;

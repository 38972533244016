import React from 'react';

interface SanGeronimoLogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'original' | 'black' | 'white';
}

const SanGeronimoLogo: React.FC<SanGeronimoLogoProps> = ({
  variant = 'original',
  ...props
}) => {
  const getColor = (defaultColor: string) => {
    switch (variant) {
      case 'black':
        return '#3D3D3D';
      case 'white':
        return '#E8E8ED';
      default:
        return defaultColor;
    }
  };

  return (
    <svg
      {...props}
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 280 46.23"
    >
      <g id="Capa_2">
        <g id="Capa_1-2">
          <path
            fill={getColor('#747476')}
            d="M276.18,25.48c-1.51-1.5-3.95-1.5-5.47,0-.91.98-1.08,2.01-1.08,5.7s.17,4.72,1.08,5.7c1.51,1.5,3.94,1.49,5.44-.01.01-.01.02-.02.03-.03.9-.98,1.08-2,1.08-5.7s-.18-4.72-1.08-5.7M278.25,38.55c-2.68,2.63-6.98,2.63-9.67,0-1.8-1.81-1.77-3.84-1.77-7.38s0-5.56,1.77-7.36c2.68-2.63,6.98-2.63,9.67,0,1.81,1.81,1.75,3.83,1.75,7.36s.06,5.57-1.75,7.38"
          />
          <polygon
            fill={getColor('#8d8c8c')}
            points="111.7 15.52 111.7 8.2 109.06 14 108.18 14 105.5 8.2 105.5 15.52 104.48 15.52 104.48 5.89 105.5 5.89 108.64 12.72 111.7 5.89 112.73 5.89 112.73 15.52 111.7 15.52"
          />
          <rect
            fill={getColor('#8d8c8c')}
            x="115.44"
            y="5.89"
            width="1.02"
            height="9.63"
          />
          <polygon
            fill={getColor('#8d8c8c')}
            points="125.34 15.52 120.22 7.8 120.22 15.52 119.19 15.52 119.19 5.89 120.17 5.89 125.28 13.59 125.28 5.89 126.32 5.89 126.32 15.52 125.34 15.52"
          />
          <polygon
            fill={getColor('#8d8c8c')}
            points="129.03 15.52 129.03 5.89 135.02 5.89 135.02 6.81 130.07 6.81 130.07 10.21 134.28 10.21 134.28 11.13 130.07 11.13 130.07 14.6 135.02 14.6 135.02 15.52 129.03 15.52"
          />
          <path
            fill={getColor('#8d8c8c')}
            d="M140.69,6.81h-2.6v3.54h2.58c.93.12,1.79-.54,1.91-1.47.01-.1.02-.19.01-.29.05-.94-.68-1.74-1.62-1.78-.1,0-.2,0-.3.01M142.6,15.54l-2.21-4.29h-2.3v4.28h-1.02V5.89h3.69c1.43-.14,2.7.9,2.84,2.33.01.11.01.22.01.34.06,1.26-.82,2.38-2.07,2.59l2.3,4.37h-1.24Z"
          />
          <path
            fill={getColor('#8d8c8c')}
            d="M148.65,7.36l-1.81,5.09h3.59l-1.78-5.09ZM151.51,15.52l-.76-2.17h-4.2l-.77,2.17h-1.15l3.54-9.63h.87l3.53,9.63h-1.07Z"
          />
          <path
            fill={getColor('#747476')}
            d="M110.35,40.51c-2.41.18-4.78-.71-6.49-2.42l1.85-1.83c1.25,1.22,2.96,1.84,4.7,1.71,2.39,0,3.79-1.04,3.79-2.81.05-.71-.22-1.41-.72-1.91-.56-.48-1.26-.76-2-.77l-2.14-.26c-1.27-.13-2.48-.64-3.45-1.47-.91-.91-1.39-2.17-1.33-3.45,0-3.2,2.3-5.39,6.13-5.39,2.1-.12,4.17.63,5.7,2.08l-1.78,1.75c-1.08-1-2.52-1.51-3.99-1.42-2.16,0-3.35,1.24-3.35,2.87-.03.64.23,1.26.69,1.7.6.46,1.31.75,2.06.84l2.09.31c1.26.09,2.47.57,3.45,1.37,1.03.97,1.58,2.35,1.5,3.76,0,3.37-2.76,5.39-6.61,5.39"
          />
          <path
            fill={getColor('#747476')}
            d="M125.77,26.16l-2.87,8.14h5.65l-2.78-8.14ZM130.58,40.36l-1.25-3.69h-7.25l-1.24,3.69h-2.97l6.73-18.35h2.24l6.72,18.41-2.98-.06Z"
          />
          <polygon
            fill={getColor('#747476')}
            points="146.77 40.36 138.29 27.45 138.29 40.36 135.5 40.36 135.5 22 138.06 22 146.54 34.89 146.54 22 149.32 22 149.32 40.36 146.77 40.36"
          />
          <path
            fill={getColor('#747476')}
            d="M169.99,38.42c-1.31,1.38-3.15,2.14-5.05,2.08-1.8.03-3.54-.68-4.81-1.96-1.81-1.81-1.78-3.84-1.78-7.38s0-5.56,1.78-7.36c1.26-1.29,3.01-2,4.81-1.96,3.4-.15,6.32,2.37,6.67,5.75h-2.81c-.28-1.9-1.95-3.28-3.87-3.2-1.02,0-2,.41-2.7,1.15-.9.98-1.15,2.01-1.15,5.7s.21,4.74,1.15,5.75c.7.74,1.68,1.16,2.7,1.15,1.14.03,2.23-.44,2.99-1.29.66-.81,1-1.84.93-2.89v-1.04h-3.92v-2.52h6.71v2.82c0,2.37-.44,3.89-1.66,5.18"
          />
          <polygon
            fill={getColor('#747476')}
            points="175.1 40.36 175.1 22 186.82 22 186.82 24.5 177.88 24.5 177.88 29.86 185.51 29.86 185.51 32.34 177.88 32.34 177.88 37.86 186.82 37.86 186.82 40.36 175.1 40.36"
          />
          <path
            fill={getColor('#747476')}
            d="M196.9,24.5h-4.12v5.75h4.12c1.59.14,2.99-1.03,3.13-2.62.14-1.59-1.03-2.99-2.62-3.13-.17-.02-.34-.02-.52,0M200.13,40.36l-3.87-7.73h-3.45v7.73h-2.78v-18.35h7.11c3.45,0,5.75,2.24,5.75,5.36.08,2.3-1.49,4.34-3.74,4.86l4.22,8.12h-3.25Z"
          />
          <path
            fill={getColor('#747476')}
            d="M212.81,20.23h-1.99l1.93-4.12h2.92l-2.87,4.12ZM214.89,25.48c-1.51-1.5-3.95-1.5-5.45,0-.91.98-1.08,2.01-1.08,5.7s.17,4.72,1.08,5.7c1.49,1.51,3.91,1.52,5.42.04.01-.01.02-.02.04-.04.91-.98,1.08-2,1.08-5.7s-.17-4.72-1.08-5.7M217.01,38.55c-2.68,2.63-6.98,2.63-9.67,0-1.81-1.81-1.77-3.84-1.77-7.38s0-5.56,1.77-7.36c2.68-2.63,6.98-2.63,9.67,0,1.81,1.81,1.75,3.83,1.75,7.36s.06,5.57-1.75,7.38"
          />
          <polygon
            fill={getColor('#747476')}
            points="233.53 40.36 225.05 27.45 225.05 40.36 222.27 40.36 222.27 22 224.82 22 233.3 34.89 233.3 22 236.09 22 236.09 40.36 233.53 40.36"
          />
          <rect
            fill={getColor('#747476')}
            x="240.23"
            y="22"
            width="2.78"
            height="18.35"
          />
          <polygon
            fill={getColor('#747476')}
            points="260.58 40.36 260.58 28.09 256.34 37.03 254.28 37.03 249.95 28.09 249.95 40.36 247.17 40.36 247.17 22 249.95 22 255.32 33.42 260.58 22 263.35 22 263.35 40.36 260.58 40.36"
          />
          <rect
            fill={getColor('#f39910')}
            x="91.33"
            width="1.44"
            height="46.23"
          />
          <path
            fill={getColor('#f39910')}
            d="M62.9,23.56c0,3.41-2.76,6.17-6.17,6.17-3.41,0-6.17-2.76-6.17-6.17s2.76-6.17,6.17-6.17,6.17,2.76,6.17,6.17h0"
          />
          <path
            fill={getColor('#747476')}
            d="M41.07,29.36l-2.3,2.3-.14.16c-.6.62-1.43.97-2.3.98-.68.02-1.34-.19-1.9-.58l-.37-.26-10.36-7.56-18.06,15.96H0L27.4,15.29c1-.84,2.46-.84,3.45,0l13.69,10.36-3.48,3.71Z"
          />
          <path
            fill={getColor('#8d8c8c')}
            d="M47.72,22.27c.99-.85,2.46-.85,3.45,0l29.92,18.09h-22.11l-17.92-11,6.65-7.09Z"
          />
        </g>
      </g>
    </svg>
  );
};

export default SanGeronimoLogo;

import React from 'react';
import LogotipoW_Blackend from '../assets/LogotipoW_Blackend.svg';
import ArrowUpIcon from './icons/ArrowUpIcon';
import LinkedinIcon from './icons/LinkedinIcon';

const blackendUrl =
  'https://www.linkedin.com/company/blackend/posts/?feedView=all';

const Footer: React.FC = () => {
  return (
    <section className="w-full h-[62vh] md:h-[40vh] bg-[#050505] flex flex-col ">
      <div className="flex flex-col md:flex-row justify-center items-start w-full h-[55vh] md:h-[35vh] space-y-10 md:space-x-10 md:space-y-0 px-14">
        <div className="flex justify-center w-full md:w-1/3 mt-10">
          <img
            src={LogotipoW_Blackend}
            alt="Logo"
            className="h-10 md:h-14 mt-0"
          />
        </div>

        <div className="relative bg-yellow w-full md:w-2/3 min-h-[40vh] md:min-h-[25vh] md:h-[34vh] p-6">
          <div className="w-full text-white text-2xl md:text-4xl font-semibold mb-2 md:mb-48">
            <p>Somos el partner tecnológico</p>
            <p>de empresas y emprendedores</p>
          </div>

          <a
            href={blackendUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="absolute bottom-10 left-6 md:bottom-[25px] md:left-6"
          >
            <LinkedinIcon className="h-6 w-6 md:h-8 md:w-8" />
          </a>

          <div className="absolute bottom-20 md:bottom-5 left-6 md:left-20 -space-y-1">
            <p className="text-white">Av Kennedy 5600, Vitacura</p>
            <p className="text-white">max@blackend.dev</p>
            <p className="text-white">+56988330550</p>
          </div>

          <div className="absolute bottom-6 right-6 border-2 border-[#0D0D0D] rounded p-3">
            <a href="#home">
              <ArrowUpIcon className="h-7 w-7" />
            </a>
          </div>
        </div>
      </div>

      <div className="flex justify-center items-center text-white text-sm w-full h-[5vh] space-x-10 bg-[#0D0D0D]">
        <p> COPYRIGHT © 2024 BLACKEND</p>
      </div>
    </section>
  );
};

export default Footer;

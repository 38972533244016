import React, { useEffect, useState } from 'react';
import colors from '../styles/colors';
import { useMenu } from '../contexts/MenuContext';

const texts = [
  { text: 'TUS IDEAS.', color: colors.yellow },
  { text: 'TUS PROYECTOS.', color: colors.blue },
  { text: 'TUS METAS.', color: colors.green },
  { text: 'TUS DESAFÍOS.', color: colors.red },
];

const AnimatedText: React.FC = () => {
  const [currentText, setCurrentText] = useState(0);
  const [isVisible, setIsVisible] = useState(true);
  const { isMenuOpen } = useMenu();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsVisible(false);
      setTimeout(() => {
        setCurrentText((prev) => (prev + 1) % texts.length);
        setIsVisible(true);
      }, 500);
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  if (isMenuOpen) return null;

  return (
    <div className="flex justify-center z-10">
      <h1
        className={`font-black italic transition-all duration-500 
        ${isVisible ? 'opacity-100 scale-100' : 'opacity-0 scale-90'} 
        text-4xl md:text-6xl lg:text-7xl text-center`}
        style={{ color: texts[currentText].color }}
      >
        {texts[currentText].text}
      </h1>
    </div>
  );
};

export default AnimatedText;

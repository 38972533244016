import React from 'react';

interface ReactLogoProps extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const ReactLogo: React.FC<ReactLogoProps> = ({ color, ...props }) => (
  <svg
    {...props}
    width="42"
    height="38"
    viewBox="0 0 42 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.001 26.7997C32.0947 26.7997 41.088 23.3078 41.088 19.0004C41.088 14.693 32.0947 11.2012 21.001 11.2012C9.9073 11.2012 0.914062 14.693 0.914062 19.0004C0.914062 23.3078 9.9073 26.7997 21.001 26.7997Z"
      stroke={color}
      stroke-width="1.31233"
    />
    <path
      d="M14.359 22.9004C19.9059 32.6703 27.3762 38.8444 31.0445 36.6907C34.7128 34.537 33.19 24.871 27.6431 15.1011C22.0962 5.33121 14.6259 -0.842944 10.9576 1.31076C7.28927 3.46447 8.81215 13.1305 14.359 22.9004Z"
      stroke={color}
      stroke-width="1.31233"
    />
    <path
      d="M14.356 15.1017C8.80918 24.8716 7.2863 34.5376 10.9546 36.6913C14.6229 38.845 22.0933 32.6708 27.6401 22.9009C33.187 13.131 34.7099 3.46501 31.0416 1.31131C27.3733 -0.842398 19.9029 5.33175 14.356 15.1017Z"
      stroke={color}
      stroke-width="1.31233"
    />
  </svg>
);

export default ReactLogo;

import React, { useEffect, useRef, useState } from 'react';
// Importa los íconos que deseas usar
import { FaLaptopCode, FaRobot, FaRocket } from 'react-icons/fa';

const OurServices: React.FC = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.3 },
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => observer.disconnect();
  }, []);

  const getTransitionStyle = (delay: number) => ({
    opacity: isVisible ? 1 : 0,
    transform: isVisible ? 'translateY(0)' : 'translateY(50px)',
    transition: `opacity 0.9s ease ${delay}s, transform 0.9s ease ${delay}s`,
  });

  // Definición de los servicios con descripciones en JSX para permitir spans
  const services = [
    {
      title: 'Software a Medida Web y Mobile',
      description: (
        <>
          Desarrollamos soluciones{' '}
          <span className="font-extrabold italic text-yellow">
            personalizadas
          </span>{' '}
          para tus necesidades específicas, tanto en plataformas web como móviles,
          asegurando un rendimiento óptimo y una experiencia de usuario excepcional.
        </>
      ),
      icon: <FaLaptopCode size={40} className="text-yellow mb-4" />,
    },
    {
      title: 'Automatizaciones para Mejorar Procesos',
      description: (
        <>
          Implementamos{' '}
          <span className="font-extrabold italic text-yellow">
            automatizaciones
          </span>{' '}
          que optimizan y agilizan tus procesos internos, reduciendo costos y mejorando
          la productividad de tu equipo.
        </>
      ),
      icon: <FaRobot size={40} className="text-yellow mb-4" />,
    },
    {
      title: 'Desarrollo Rápido de MVPs',
      description: (
        <>
          Creamos Productos Mínimos Viables (
          <span className="font-extrabold italic text-yellow">MVPs</span>) que te
          permiten validar tus ideas rápidamente en el mercado, facilitando la toma de
          decisiones informadas para el crecimiento de tu negocio.
        </>
      ),
      icon: <FaRocket size={40} className="text-yellow mb-4" />,
    },
  ];

  return (
    <section
      ref={sectionRef}
      className="w-full min-h-screen bg-black text-white flex items-center justify-center py-12 top-20"
    >
      <div className="flex flex-col items-center w-full md:w-4/5 lg:w-3/4 px-4 md:px-8">
        <h1
          style={getTransitionStyle(0.1)}
          className="italic font-extrabold text-3xl md:text-4xl text-center text-yellow mb-8"
        >
          NUESTROS SERVICIOS
        </h1>
        <p
          style={getTransitionStyle(0.3)}
          className="text-lg md:text-2xl font-medium text-center text-white mb-12"
        >
          Ofrecemos una gama completa de servicios diseñados para impulsar tu negocio al
          siguiente nivel.
        </p>
        <div
          style={getTransitionStyle(0.5)}
          className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full"
        >
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-gray-800 rounded-lg p-6 hover:bg-gray-700 transition-colors duration-300 flex flex-col items-center"
            >
              <div className="mb-4">{service.icon}</div>
              <h2 className="text-xl md:text-2xl font-bold text-center mb-4">
                {service.title}
              </h2>
              <p className="text-center text-gray-300">{service.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default OurServices;

import React from 'react';

interface BadgeIconProps extends React.SVGProps<SVGSVGElement> {
  isHovered?: boolean;
}

const BadgeIcon: React.FC<BadgeIconProps> = ({
  isHovered = false,
  ...props
}) => {
  const getColor = (defaultColor: string) => {
    return isHovered ? '#FFFFFF' : defaultColor;
  };

  return (
    <svg
      {...props}
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.77 9.479C17.2496 8.55925 17.5001 7.53729 17.5 6.5C17.5 4.77609 16.8152 3.12279 15.5962 1.90381C14.3772 0.684819 12.7239 0 11 0C9.2761 0 7.6228 0.684819 6.40382 1.90381C5.18483 3.12279 4.50001 4.77609 4.50001 6.5C4.49996 7.53729 4.75039 8.55925 5.23001 9.479L0.230011 20.473L5.88401 19.06L8.50001 20.369L11 14.538L13.5 20.369L16.117 19.06L21.769 20.473L16.77 9.479ZM11 2C11.89 2 12.7601 2.26392 13.5001 2.75839C14.2401 3.25285 14.8169 3.95566 15.1575 4.77792C15.4981 5.60019 15.5872 6.50499 15.4135 7.37791C15.2399 8.25082 14.8113 9.05264 14.182 9.68198C13.5527 10.3113 12.7508 10.7399 11.8779 10.9135C11.005 11.0872 10.1002 10.9981 9.27794 10.6575C8.45567 10.3169 7.75286 9.74009 7.2584 9.00007C6.76393 8.26005 6.50001 7.39002 6.50001 6.5C6.50133 5.30693 6.97586 4.16311 7.81949 3.31948C8.66312 2.47585 9.80694 2.00132 11 2ZM7.50001 17.631L6.11601 16.94L3.76801 17.527L6.60901 11.278C7.43872 12.0442 8.45428 12.5802 9.55501 12.833L7.50001 17.631ZM15.885 16.94L14.502 17.631L12.446 12.831C13.5467 12.5782 14.5623 12.0422 15.392 11.276L18.233 17.525L15.885 16.94Z"
        fill={getColor('#5D5D5D')}
      />
    </svg>
  );
};

export default BadgeIcon;

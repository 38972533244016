import React from 'react';

const SettingsIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.816 2C12.6124 1.41545 12.2318 0.908771 11.7273 0.550228C11.2227 0.191685 10.619 -0.000946045 10 -0.000946045C9.381 -0.000946045 8.77734 0.191685 8.27275 0.550228C7.76815 0.908771 7.38765 1.41545 7.184 2H0V4H7.184C7.38765 4.58454 7.76815 5.09123 8.27275 5.44977C8.77734 5.80831 9.381 6.00094 10 6.00094C10.619 6.00094 11.2227 5.80831 11.7273 5.44977C12.2318 5.09123 12.6124 4.58454 12.816 4H18V2H12.816ZM10 4C9.80222 4 9.60888 3.94135 9.44443 3.83147C9.27998 3.72159 9.15181 3.56541 9.07612 3.38268C9.00043 3.19996 8.98063 2.99889 9.01921 2.80491C9.0578 2.61093 9.15304 2.43275 9.29289 2.29289C9.43275 2.15304 9.61093 2.0578 9.80491 2.01921C9.99889 1.98063 10.2 2.00043 10.3827 2.07612C10.5654 2.15181 10.7216 2.27998 10.8315 2.44443C10.9414 2.60888 11 2.80222 11 3C11 3.26522 10.8946 3.51957 10.7071 3.70711C10.5196 3.89464 10.2652 4 10 4Z"
      fill="white"
    />
    <path
      d="M4 6C3.38174 6.00256 2.77937 6.19608 2.2753 6.55409C1.77123 6.91209 1.39008 7.41709 1.184 8H0V10H1.184C1.38765 10.5845 1.76815 11.0912 2.27275 11.4498C2.77734 11.8083 3.381 12.0009 4 12.0009C4.619 12.0009 5.22266 11.8083 5.72725 11.4498C6.23185 11.0912 6.61235 10.5845 6.816 10H18V8H6.816C6.60992 7.41709 6.22877 6.91209 5.7247 6.55409C5.22063 6.19608 4.61826 6.00256 4 6ZM4 10C3.80222 10 3.60888 9.94135 3.44443 9.83147C3.27998 9.72159 3.15181 9.56541 3.07612 9.38268C3.00043 9.19996 2.98063 8.99889 3.01921 8.80491C3.0578 8.61093 3.15304 8.43275 3.29289 8.29289C3.43275 8.15304 3.61093 8.0578 3.80491 8.01921C3.99889 7.98063 4.19996 8.00043 4.38268 8.07612C4.56541 8.15181 4.72159 8.27998 4.83147 8.44443C4.94135 8.60888 5 8.80222 5 9C5 9.26522 4.89464 9.51957 4.70711 9.70711C4.51957 9.89464 4.26522 10 4 10Z"
      fill="white"
    />
    <path
      d="M13 12C12.3817 12.0026 11.7794 12.1961 11.2753 12.5541C10.7712 12.9121 10.3901 13.4171 10.184 14H0V16H10.184C10.3876 16.5845 10.7682 17.0912 11.2727 17.4498C11.7773 17.8083 12.381 18.0009 13 18.0009C13.619 18.0009 14.2227 17.8083 14.7273 17.4498C15.2318 17.0912 15.6124 16.5845 15.816 16H18V14H15.816C15.6099 13.4171 15.2288 12.9121 14.7247 12.5541C14.2206 12.1961 13.6183 12.0026 13 12ZM13 16C12.8022 16 12.6089 15.9414 12.4444 15.8315C12.28 15.7216 12.1518 15.5654 12.0761 15.3827C12.0004 15.2 11.9806 14.9989 12.0192 14.8049C12.0578 14.6109 12.153 14.4327 12.2929 14.2929C12.4327 14.153 12.6109 14.0578 12.8049 14.0192C12.9989 13.9806 13.2 14.0004 13.3827 14.0761C13.5654 14.1518 13.7216 14.28 13.8315 14.4444C13.9414 14.6089 14 14.8022 14 15C14 15.2652 13.8946 15.5196 13.7071 15.7071C13.5196 15.8946 13.2652 16 13 16Z"
      fill="white"
    />
  </svg>
);

export default SettingsIcon;

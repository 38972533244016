import React from 'react';

interface PlatanusLogoProps extends React.SVGProps<SVGSVGElement> {
  variant?: 'original' | 'black' | 'white';
}

const PlatanusLogo: React.FC<PlatanusLogoProps> = ({
  variant = 'original',
  ...props
}) => {
  const getColor = (defaultColor: string) => {
    switch (variant) {
      case 'black':
        return '#3D3D3D';
      case 'white':
        return '#E8E8ED';
      default:
        return defaultColor;
    }
  };

  return (
    <svg
      {...props}
      width="280"
      height="56"
      viewBox="0 0 280 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_213_547)">
        <path
          d="M88.0017 29.6507C88.0017 26.1714 87.1454 23.4942 85.4328 21.6192C83.7202 19.7442 81.4368 18.8092 78.5824 18.8092C76.983 18.8092 75.7281 18.8682 74.8177 18.9814C73.9417 19.0946 73.2379 19.2324 72.7064 19.3849V38.1989C73.3511 38.7353 74.2862 39.2521 75.5017 39.7491C76.7173 40.2462 78.0509 40.4971 79.4978 40.4971C81.0185 40.4971 82.3127 40.2314 83.3757 39.695C84.4781 39.1192 85.3738 38.3564 86.0578 37.4017C86.7419 36.4076 87.2389 35.2609 87.5391 33.9617C87.8442 32.6231 87.9968 31.191 87.9968 29.6605L88.0017 29.6507ZM93.5381 29.6507C93.5381 31.9046 93.233 33.9912 92.6277 35.9007C92.0568 37.8101 91.2005 39.4588 90.0588 40.8318C88.9171 42.2097 87.5096 43.2777 85.8364 44.0454C84.2025 44.8082 82.3177 45.192 80.1868 45.192C78.4742 45.192 76.9535 44.9607 75.6198 44.503C74.3255 44.0454 73.3561 43.6025 72.7114 43.1842V55.1133H67.4062V15.6005C68.6612 15.2954 70.2212 14.9706 72.0864 14.6261C73.9909 14.2422 76.1759 14.0503 78.6513 14.0503C80.9348 14.0503 82.987 14.4145 84.8127 15.1379C86.6385 15.8662 88.1986 16.8948 89.4929 18.2334C90.7872 19.5719 91.7763 21.2156 92.4604 23.1645C93.1838 25.0739 93.5431 27.2343 93.5431 29.6458L93.5381 29.6507Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M109.424 45.0787C106.151 45 103.833 44.2963 102.46 42.9577C101.092 41.6191 100.408 39.5374 100.408 36.7077V0.915354L105.713 0V35.8465C105.713 36.7274 105.787 37.4508 105.94 38.0266C106.092 38.6024 106.338 39.06 106.683 39.4045C107.027 39.749 107.48 40.0148 108.051 40.2067C108.622 40.3592 109.326 40.4921 110.162 40.6102L109.419 45.0837L109.424 45.0787Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M125.685 40.6644C126.94 40.6644 128.042 40.6447 128.997 40.6053C129.986 40.5266 130.803 40.4134 131.453 40.2608V31.373C131.074 31.1811 130.444 31.0285 129.568 30.9154C128.731 30.7628 127.703 30.6841 126.487 30.6841C125.69 30.6841 124.833 30.7431 123.918 30.8563C123.042 30.9695 122.225 31.2205 121.462 31.5994C120.739 31.9439 120.129 32.4409 119.637 33.0906C119.14 33.7008 118.893 34.5226 118.893 35.5561C118.893 37.4656 119.504 38.8041 120.719 39.5719C121.935 40.3002 123.593 40.6595 125.685 40.6595V40.6644ZM125.227 13.937C127.358 13.937 129.144 14.2224 130.591 14.7982C132.073 15.3346 133.254 16.1171 134.13 17.1506C135.045 18.1447 135.69 19.3504 136.069 20.7628C136.448 22.1408 136.64 23.6663 136.64 25.3494V43.9911C136.182 44.0699 135.537 44.1831 134.701 44.3356C133.903 44.4488 132.988 44.5669 131.959 44.6801C130.931 44.7933 129.809 44.8917 128.593 44.9656C127.412 45.0787 126.236 45.1378 125.055 45.1378C123.382 45.1378 121.841 44.9656 120.434 44.6211C119.026 44.2766 117.811 43.7402 116.782 43.0167C115.754 42.2539 114.956 41.2598 114.386 40.0345C113.815 38.8091 113.529 37.3376 113.529 35.6201C113.529 33.9026 113.854 32.5591 114.499 31.378C115.183 30.1919 116.098 29.2372 117.24 28.5089C118.382 27.7805 119.71 27.249 121.236 26.9045C122.757 26.56 124.356 26.3878 126.029 26.3878C126.561 26.3878 127.112 26.4272 127.683 26.501C128.254 26.5404 128.785 26.6142 129.282 26.7323C129.814 26.811 130.271 26.8848 130.65 26.9636C131.029 27.0423 131.295 27.0965 131.448 27.1358V25.6447C131.448 24.7638 131.354 23.9026 131.162 23.0659C130.97 22.185 130.631 21.4222 130.134 20.7726C129.637 20.0837 128.953 19.5472 128.081 19.1683C127.245 18.75 126.142 18.5384 124.769 18.5384C123.017 18.5384 121.477 18.6713 120.148 18.9419C118.854 19.1732 117.885 19.4193 117.24 19.69L116.61 15.2756C117.294 14.9705 118.436 14.685 120.035 14.4144C121.635 14.1093 123.362 13.9567 125.227 13.9567V13.937Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M149.93 14.6849H161.17V19.1583H149.93V32.9231C149.93 34.4142 150.043 35.6593 150.275 36.6534C150.501 37.6081 150.845 38.3759 151.303 38.9467C151.761 39.4831 152.332 39.8621 153.016 40.0934C153.7 40.3247 154.502 40.4379 155.412 40.4379C157.012 40.4379 158.286 40.2656 159.236 39.9211C160.225 39.5373 160.909 39.2715 161.288 39.119L162.317 43.5333C161.785 43.7991 160.85 44.1239 159.522 44.5078C158.188 44.9261 156.667 45.1377 154.955 45.1377C152.937 45.1377 151.264 44.8916 149.935 44.3896C148.641 43.8532 147.597 43.0707 146.795 42.0373C145.998 41.0038 145.427 39.744 145.083 38.2528C144.778 36.7223 144.625 34.9654 144.625 32.9772V6.36799L149.93 5.45264V14.6849Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M177.485 40.6644C178.739 40.6644 179.842 40.6447 180.797 40.6053C181.786 40.5266 182.603 40.4134 183.252 40.2608V31.373C182.873 31.1811 182.243 31.0285 181.367 30.9154C180.531 30.7628 179.502 30.6841 178.287 30.6841C177.489 30.6841 176.633 30.7431 175.718 30.8563C174.842 30.9695 174.025 31.2205 173.262 31.5994C172.539 31.9439 171.928 32.4409 171.436 33.0906C170.939 33.7008 170.693 34.5226 170.693 35.5561C170.693 37.4656 171.303 38.8041 172.519 39.5719C173.735 40.3002 175.393 40.6595 177.485 40.6595V40.6644ZM177.032 13.937C179.163 13.937 180.949 14.2224 182.396 14.7982C183.877 15.3346 185.058 16.1171 185.934 17.1506C186.85 18.1447 187.494 19.3504 187.873 20.7628C188.252 22.1408 188.444 23.6663 188.444 25.3494V43.9911C187.987 44.0699 187.342 44.1831 186.505 44.3356C185.708 44.4488 184.793 44.5669 183.764 44.6801C182.736 44.7933 181.614 44.8917 180.398 44.9656C179.217 45.0787 178.041 45.1378 176.86 45.1378C175.186 45.1378 173.646 44.9656 172.239 44.6211C170.831 44.2766 169.615 43.7402 168.587 43.0167C167.558 42.2539 166.761 41.2598 166.19 40.0345C165.619 38.8091 165.334 37.3376 165.334 35.6201C165.334 33.9026 165.659 32.5591 166.303 31.378C166.988 30.1919 167.903 29.2372 169.045 28.5089C170.186 27.7805 171.515 27.249 173.041 26.9045C174.561 26.56 176.161 26.3878 177.834 26.3878C178.365 26.3878 178.917 26.4272 179.488 26.501C180.058 26.5404 180.59 26.6142 181.087 26.7323C181.618 26.811 182.076 26.8848 182.455 26.9636C182.834 27.0423 183.1 27.0965 183.252 27.1358V25.6447C183.252 24.7638 183.159 23.9026 182.967 23.0659C182.775 22.185 182.435 21.4222 181.938 20.7726C181.441 20.0837 180.757 19.5472 179.886 19.1683C179.05 18.75 177.947 18.5384 176.574 18.5384C174.822 18.5384 173.282 18.6713 171.953 18.9419C170.659 19.1732 169.689 19.4193 169.045 19.69L168.415 15.2756C169.099 14.9705 170.24 14.685 171.84 14.4144C173.439 14.1093 175.167 13.9567 177.032 13.9567V13.937Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M196.771 15.5414C197.987 15.2363 199.606 14.9115 201.624 14.567C203.642 14.2225 205.959 14.0503 208.587 14.0503C210.945 14.0503 212.903 14.3948 214.463 15.0838C216.023 15.7334 217.259 16.6684 218.174 17.8938C219.124 19.0798 219.793 20.5119 220.172 22.195C220.551 23.878 220.743 25.7334 220.743 27.756V44.503H215.433V28.9027C215.433 27.067 215.3 25.4971 215.034 24.1979C214.808 22.8987 214.404 21.8456 213.838 21.0434C213.268 20.2412 212.505 19.6654 211.555 19.321C210.605 18.9371 209.424 18.7452 208.017 18.7452C207.446 18.7452 206.855 18.7649 206.245 18.8042C205.635 18.8436 205.049 18.8977 204.478 18.9765C203.947 19.0158 203.45 19.07 202.992 19.1487C202.574 19.2275 202.269 19.2816 202.077 19.321V44.4981H196.771V15.5414Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M252.681 43.6467C251.466 43.9518 249.847 44.2766 247.829 44.6211C245.851 44.9656 243.548 45.1378 240.924 45.1378C238.641 45.1378 236.722 44.813 235.162 44.1634C233.602 43.4744 232.347 42.5197 231.397 41.2943C230.447 40.0689 229.763 38.6368 229.34 36.9931C228.922 35.3101 228.715 33.4547 228.715 31.4321V14.6851H234.02V30.2855C234.02 33.9173 234.591 36.5158 235.733 38.0856C236.874 39.6555 238.794 40.438 241.495 40.438C242.066 40.438 242.657 40.4183 243.267 40.379C243.877 40.3396 244.448 40.3002 244.98 40.2658C245.511 40.187 245.988 40.1329 246.407 40.0935C246.864 40.0148 247.184 39.941 247.376 39.8622V14.6851H252.681V43.6516V43.6467Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M268.244 40.6644C270.414 40.6644 272.008 40.3789 273.037 39.8032C274.1 39.2274 274.636 38.312 274.636 37.0522C274.636 35.7923 274.124 34.7195 273.096 33.9567C272.067 33.1939 270.374 32.3327 268.017 31.378C266.875 30.9203 265.773 30.4626 264.705 30C263.677 29.503 262.786 28.9272 262.023 28.2776C261.26 27.628 260.655 26.8455 260.197 25.9252C259.74 25.0098 259.513 23.878 259.513 22.5394C259.513 19.9016 260.483 17.815 262.422 16.2894C264.361 14.7195 267.008 13.937 270.355 13.937C271.191 13.937 272.028 13.9961 272.865 14.1093C273.701 14.188 274.484 14.3012 275.207 14.4537C275.931 14.5669 276.556 14.6998 277.092 14.8573C277.663 15.0098 278.101 15.1427 278.406 15.2608L277.436 19.8474C276.866 19.5423 275.97 19.2372 274.754 18.9321C273.539 18.5876 272.072 18.4154 270.36 18.4154C268.873 18.4154 267.584 18.7205 266.477 19.3307C265.374 19.9065 264.823 20.8219 264.823 22.0817C264.823 22.7313 264.936 23.3071 265.168 23.8041C265.434 24.3012 265.812 24.7589 266.309 25.1821C266.841 25.5659 267.491 25.9301 268.248 26.2697C269.011 26.6142 269.922 26.9783 270.99 27.3573C272.397 27.8937 273.652 28.4301 274.754 28.9616C275.857 29.4587 276.792 30.0492 277.55 30.7382C278.347 31.4272 278.957 32.2687 279.375 33.2628C279.794 34.2175 280.005 35.4035 280.005 36.8209C280.005 39.5719 278.977 41.6585 276.925 43.0709C274.907 44.4833 272.018 45.1919 268.253 45.1919C265.63 45.1919 263.573 44.9606 262.092 44.503C260.611 44.0846 259.602 43.7549 259.07 43.5285L260.04 38.9419C260.65 39.1732 261.62 39.5177 262.948 39.9754C264.277 40.4331 266.049 40.6644 268.253 40.6644H268.244Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M41.3433 0.113227C41.5156 0.226416 41.6288 0.339605 41.7419 0.457716C47.052 8.00201 51.5057 16.4666 51.5057 27.6674C51.5057 38.8681 46.8797 47.3918 41.7419 54.4784C41.5697 54.6506 41.5156 54.8229 41.2843 54.9902C41.0579 55.1624 40.6002 55.1034 40.6002 55.1034H35.6297C35.6297 55.1034 35.4575 55.1034 35.4034 55.0443C35.3443 54.9311 35.4034 54.8721 35.4624 54.8179C37.4605 50.817 39.4585 46.7569 40.9447 42.1851C42.3719 37.8396 43.799 33.0955 43.9122 27.5492C44.0845 21.4912 43.2282 17.8298 42.0274 13.942C40.2016 8.11027 37.6278 3.47937 35.5756 0.452794C35.4034 0.226416 35.4034 0.167361 35.4624 0.0541723C35.5756 -0.00488281 35.7479 -0.00488281 35.7479 -0.00488281H40.6593C40.6593 -0.00488281 41.1711 -0.00488281 41.3433 0.108306V0.113227Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M37.3478 10.2902C37.0033 9.43386 33.2386 1.02835 33.1204 0.801974C33.0614 0.688785 33.0073 0.742919 32.9482 0.742919C32.8891 0.801974 32.835 0.856108 32.8891 1.08741C33.2336 4.17304 33.3468 8.69075 32.3773 13.8335C31.7474 17.2045 30.9502 20.2951 28.3813 24.8669C26.0978 28.9811 23.1254 32.0126 20.3301 34.7587C17.4167 37.6179 14.2769 39.9014 11.1913 42.1356C11.1323 42.1947 11.0781 42.1947 11.0781 42.3079C11.0781 42.3669 11.2504 42.4801 11.2504 42.4801L15.0201 44.4831C15.0201 44.4831 15.3645 44.6553 15.5909 44.6553C15.8173 44.5963 15.9354 44.5421 16.1027 44.4831C22.8399 41.112 29.6362 36.5943 34.149 28.0756C36.7769 23.1002 38.2041 18.1839 38.0909 13.4398C37.9777 12.0667 37.6332 11.0382 37.3478 10.2951V10.2902Z"
          fill={getColor('#E8E8ED')}
        />
        <path
          d="M30.3248 9.6603C30.3839 8.91719 30.5512 1.48609 30.5512 1.31385C30.5512 1.1416 30.4921 1.1416 30.438 1.1416C30.3839 1.1416 30.3248 1.20066 30.2657 1.36798C29.4685 3.7105 27.9232 7.02743 25.4675 10.4575C23.8091 12.6869 22.1555 14.6898 18.7303 17.0865C15.6447 19.2568 12.3917 20.4575 9.42421 21.432C6.28445 22.4605 3.25787 23.0904 0.172244 23.6022C0.113189 23.6022 0.0590551 23.6022 0 23.6613C0 23.7203 0.0590551 23.8335 0.0590551 23.8335L2.11614 26.5796C2.11614 26.5796 2.28839 26.865 2.46063 26.9241C2.63287 26.9831 2.74606 26.9831 2.91831 26.9831C8.97146 26.9241 15.5364 26.0136 21.7618 21.3827C25.4183 18.6957 28.1594 15.6101 29.6998 12.1209C30.1575 11.1514 30.2707 10.2902 30.3297 9.6603H30.3248Z"
          fill={getColor('#E8E8ED')}
        />
      </g>
      <defs>
        <clipPath id="clip0_213_547">
          <rect width="280" height="55.1181" fill={getColor('#E8E8ED')} />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PlatanusLogo;

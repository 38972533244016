import React from 'react';

interface PythonLogoProps extends React.SVGProps<SVGSVGElement> {
  color: string;
}

const PythonLogo: React.FC<PythonLogoProps> = ({ color, ...props }) => (
  <svg
    {...props}
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.821 0.000306602C17.2502 0.00741005 15.7501 0.137792 14.4301 0.365101C10.5417 1.03366 9.83569 2.433 9.83569 5.01363V8.42185H19.0245V9.55793H9.83569H6.38721C3.71668 9.55793 1.37829 11.1201 0.646871 14.0918C-0.196811 17.4981 -0.234233 19.6237 0.646871 23.1804C1.30004 25.8279 2.85991 27.7143 5.53044 27.7143H8.68976V23.6286C8.68976 20.6769 11.3139 18.0733 14.4301 18.0733H23.6082C26.1631 18.0733 28.2026 16.026 28.2026 13.529V5.01363C28.2026 2.59011 26.1018 0.769576 23.6082 0.365101C22.0297 0.109378 20.3919 -0.00679695 18.821 0.000306602ZM13.8518 2.74148C14.8009 2.74148 15.576 3.50814 15.576 4.4508C15.576 5.39012 14.8009 6.1497 13.8518 6.1497C12.8992 6.1497 12.1275 5.39012 12.1275 4.4508C12.1275 3.50814 12.8992 2.74148 13.8518 2.74148Z"
      fill={color}
    />
    <path
      d="M29.2701 9.99707V13.979C29.2701 17.0661 26.5935 19.6644 23.5414 19.6644H14.382C11.873 19.6644 9.79688 21.7641 9.79688 24.2211V32.7596C9.79688 35.1898 11.9579 36.6191 14.382 37.3163C17.2847 38.151 20.0683 38.3018 23.5414 37.3163C25.85 36.6627 28.1265 35.3473 28.1265 32.7596V29.3421H18.967V28.2029H28.1265H32.7116C35.3767 28.2029 36.3698 26.3851 37.2966 23.6567C38.254 20.8478 38.2133 18.1466 37.2966 14.5433C36.638 11.9489 35.3801 9.99707 32.7116 9.99707H29.2701ZM24.1186 31.6205C25.0692 31.6205 25.8393 32.3821 25.8393 33.324C25.8393 34.2692 25.0692 35.038 24.1186 35.038C23.1713 35.038 22.3978 34.2692 22.3978 33.324C22.3978 32.3821 23.1713 31.6205 24.1186 31.6205Z"
      fill={color}
    />
  </svg>
);

export default PythonLogo;

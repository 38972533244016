import React from 'react';

import Video1 from '../assets/videos/Video1.mp4';
import Video2 from '../assets/videos/Video2.mp4';
import Video3 from '../assets/videos/Video3.mp4';

import BadgeIcon from './icons/BadgeIcon';
import ShieldIcon from './icons/ShieldIcon';
import ArrowIcon from './icons/ArrowIcon';

interface Service {
  icon: React.FC<any>;
  video: string;
  text: string;
  name: string;
}

const services: Service[] = [
  {
    icon: BadgeIcon,
    video: Video1,
    text: 'Desarrollo a Medida: Creamos aplicaciones personalizadas que se adaptan a tus necesidades únicas.',
    name: 'Desarrollo a Medida',
  },
  {
    icon: ShieldIcon,
    video: Video2,
    text: 'Implementamos soluciones seguras para proteger la integridad y privacidad de tus datos en todo momento.',
    name: 'Seguridad de Datos',
  },
  {
    icon: ArrowIcon,
    video: Video3,
    text: 'Optimización y Escalabilidad: Mejoramos el rendimiento de tus procesos y aseguramos que puedan crecer junto con tu negocio.',
    name: 'Optimización y Escalabilidad',
  },
];


const Services: React.FC = () => {
  const [videoSrc, setVideoSrc] = React.useState(Video1);
  const [selectedVideo, setSelectedVideo] = React.useState('Video1');
  const [isHovered, setIsHovered] = React.useState('Video1');

  const handleVideoChange = (video: string, videoName: string) => {
    setVideoSrc(video);
    setSelectedVideo(videoName);
  };

  return (
    <section className="relative w-full min-h-[150vh] md:min-h-[100vh] md:h-screen z-0 flex flex-col items-center bg-black">
      <video
        key={videoSrc}
        src={videoSrc}
        className="hidden md:block absolute top-0 left-0 h-full w-1/2 object-cover transition-opacity duration-500 opacity-0"
        autoPlay
        loop
        muted
        onLoadedData={(e) => e.currentTarget.classList.add('opacity-100')}
      ></video>

      <div className="absolute right-0 md:top-0 w-full md:w-1/2 min-h-[150vh] md:min-h-[100vh] md:h-screen flex flex-col justify-center items-center space-y-8 px-10 md:px-0">
        {services.map(({ icon: Icon, video, text, name }, index) => (
          <div
            key={index}
            className={`flex flex-col justify-center items-center md:flex-row md:justify-center md:items-center rounded-lg md:rounded w-full md:w-[700px] text-xl md:text-2xl space-y-8 md:space-y-0 md:space-x-8 px-0 md:px-2 py-4 md:py-6 group cursor-pointer transition-all duration-500
              ${selectedVideo === name ? 'bg-white text-[#050505] h-auto md:h-[16%]' : 'bg-black text-[#5D5D5D] hover:bg-white hover:text-[#050505] md:h-[16%] h-3/4'}
            `}
            onClick={() => handleVideoChange(video, name)}
            onMouseEnter={() => setIsHovered(name)}
            onMouseLeave={() => setIsHovered('')}
          >
            <video
              src={video}
              className={`block md:hidden object-cover w-full rounded-t-lg transition-all duration-500 ${selectedVideo === name ? 'h-[200px]' : 'h-0'}`}
              autoPlay
              loop
              muted
            ></video>

            <div
              className={`flex items-center justify-center w-[90px] h-[90px] p-4 mr-2 rounded border-4 group-hover:bg-black group-hover:border-black ${selectedVideo === name ? 'bg-black border-black' : ''}`}
            >
              <Icon
                className="w-10 h-10"
                isHovered={isHovered === name || selectedVideo === name}
              />
            </div>
            <p className="text-center md:text-left px-7 md:px-0 py-10">{text}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;

import React, { useState, useEffect } from 'react';
import { useMenu } from '../contexts/MenuContext';
import logoWhite from '../assets/LogotipoW_Blackend.svg';
import logoBlack from '../assets/LogotipoB_Blackend.svg';
import blackendBlack from '../assets/Blackend_B.svg';
import blackendWhite from '../assets/Blackend_W.svg';
import MenuIcon from './icons/MenuIcon';
import ExitIcon from './icons/ExitIcon';

const Navbar: React.FC = () => {
  const [isWhiteBackground, setIsWhiteBackground] = useState(false);
  const { isMenuOpen, setIsMenuOpen } = useMenu();

  const styles = {
    navbar: `fixed top-0 left-0 w-full md:p-4 p-8 transition-all duration-300 ${isWhiteBackground ? 'bg-white text-black' : 'bg-black text-white'} z-50`,
    button: `font-bold text-base rounded border-2 px-6 py-1 ${isWhiteBackground ? 'border-black' : 'border-white'}`,
    underline: `hover:font-bold hover:underline hover:underline-offset-[5px] ${isWhiteBackground ? 'hover:decoration-black' : 'hover:decoration-white'}`,
  };

  const scrollToId = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      toggleMenu();
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      sections.forEach((section) => {
        const rect = section.getBoundingClientRect();
        if (rect.top <= 0 && rect.bottom > 0) {
          setIsWhiteBackground(section.classList.contains('bg-lightGray'));
        }
      });
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <nav className={styles.navbar}>
      <div className="flex items-center justify-between mx-9">
        <img
          src={isWhiteBackground ? blackendBlack : blackendWhite}
          className="h-8 block md:hidden absolute top-4 left-1 pr-4"
          alt="Mobile Logo"
        />

        <img
          src={isWhiteBackground ? logoBlack : logoWhite}
          className="h-7 hidden md:block"
          alt="Logo"
        />

        <div className="md:hidden absolute top-4 right-4">
          <button
            onClick={toggleMenu}
            className={`text-xl focus:outline-none ${isWhiteBackground ? 'text-black' : 'text-white'}`}
          >
            <MenuIcon
              isWhiteBackground={isWhiteBackground}
              className="w-7 h-7"
            />
          </button>
        </div>

        <ul className="hidden md:flex space-x-9 flex-grow justify-center">
          <li className={`text-base ${styles.underline}`}>
            <a href="#home">Conócenos</a>
          </li>
          <li className={`text-base ${styles.underline}`}>
            <a href="#success-stories">Casos de éxito</a>
          </li>
          <li className={`text-base ${styles.underline}`}>
            <a href="#our-services">Servicios</a>
          </li>
          <li className={`text-base ${styles.underline}`}>
            <a href="#team">Equipo</a>
          </li>
          <li className={`text-base ${styles.underline}`}>
            <a href="#faq">Preguntas frecuentes</a>
          </li>
        </ul>

        <button className={`hidden md:block ${styles.button}`}>
          <a href="#contact-form">CONTÁCTANOS</a>
        </button>
      </div>

      <div
        className={`md:hidden fixed top-0 right-0 w-full h-full bg-black text-white z-20 p-4 flex flex-col justify-between transition-transform duration-300 ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <button
          onClick={toggleMenu}
          className="flex justify-center items-center text-2xl absolute top-6 right-6 focus:outline-none"
        >
          <span className="mr-2">Salir</span>
          <ExitIcon className="w-5 h-5" />
        </button>

        <div className="flex flex-col items-start w-2/3 max-w-md mt-[90px] pl-5">
          <ul className="space-y-10">
            <li className="text-4xl md:text-5xl font-medium hover:font-semibold">
              <a href="#home" onClick={toggleMenu}>
                Conócenos
              </a>
            </li>
            <li className="text-4xl md:text-5xl font-medium hover:font-semibold">
              <a href="#success-stories" onClick={toggleMenu}>
                Casos de éxito
              </a>
            </li>
            <li className="text-4xl md:text-5xl font-medium hover:font-semibold">
              <a href="#our-services" onClick={toggleMenu}>
                Servicios
              </a>
            </li>
            <li className="text-4xl md:text-5xl font-medium hover:font-semibold">
              <a href="#team" onClick={toggleMenu}>
                Equipo
              </a>
            </li>
            <li className="text-4xl md:text-5xl font-medium hover:font-semibold">
              <a href="#faq" onClick={toggleMenu}>
                Preguntas frecuentes
              </a>
            </li>
          </ul>
        </div>

        <div className="flex flex-col items-center mt-auto">
          <button
            className={`bg-black text-white font-bold text-xl md:text-2xl rounded border-2 border-white px-20 py-3 md:px-20 md:py-4 mt-4`}
            onClick={() => scrollToId('contact-form')}
            
          >
            CONVERSEMOS
          </button>
          <img src={logoWhite} className="h-6 my-6 md:h-6" alt="Logo" />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

import React from 'react';
import Example from '../assets/team/imgs/example.png';
import Max from '../assets/team/imgs/Max.jpeg';
import Img3 from '../assets/team/imgs/Nico.jpg';
import Img5 from '../assets/team/imgs/Belen.jpg';
import Catota from '../assets/team/imgs/catota.jpeg';

import GifExample from '../assets/team/gifs/example.png';
import GifExample2 from '../assets/team/gifs/example_2.png';
import Luigi from '../assets/team/gifs/luigi.gif';
import Gif5 from '../assets/team/gifs/naruto.gif';
import Gif6 from '../assets/team/gifs/letsgo.gif';
import Gif7 from '../assets/team/gifs/goku.gif';

import '../TeamCss.css';

interface Member {
  name: string;
  role: string;
  image: string;
  gif: string;
  text: string;
  gradient: string;
}

const team: Member[] = [
  {
    name: 'Max Narea',
    role: 'CEO | Desarrollador',
    image: Max,
    gif: Gif6,
    text: '',
    gradient: 'from-[#003DDA] via-[#0EEA4B] to-[#B5F20C]',
  },
  {
    name: 'Nicolás Jimenez',
    role: 'Desarrollador',
    image: Img3,
    gif: Gif7,
    text: '',
    gradient: 'from-[#F20C28] via-[#EA8F0E] to-[#1129EA]',
  },
  {
    name: 'Catalina Narea',
    role: 'Encargada de finanzas',
    image: Catota,
    gif: Luigi,
    text: '',
    gradient: 'from-[#0EEA4B] to-[#F20C28]',
  },
  {
    name: 'Jair Zuñiga',
    role: 'Diseñador UX/UI',
    image: Example,
    gif: GifExample,
    text: '',
    gradient: 'from-[#1129EA] to-[#B5F20C]',
  },
  {
    name: 'Belén Silva',
    role: 'Desarrolladora',
    image: Img5,
    gif: Gif5,
    text: '',
    gradient: 'from-[#B5F20C] to-[#F20C28]',
  },
];

const Team: React.FC = () => {
  return (
    <section className="w-full h-auto md:h-screen z-0 flex flex-col md:flex-row md:justify-center md:items-center bg-[#050505] space-y-10 md:space-y-0 md:space-x-20 py-40">
      <div className="flex flex-col justify-center items-center w-full pt-28">
        <span className="text-[30px] md:text-3xl text-white text-4xl mb-2 mt-20 md:mt-0">
          CONOCE A
        </span>
        <p className="text-4xl md:text-6xl text-white font-bold">
          NUESTRO{' '}
          <strong className="italic font-black text-yellow">EQUIPO</strong>
        </p>
        {/* Contenedor principal de los miembros del equipo */}
        <div className="mt-10 w-full max-w-[90%] h-auto py-10 mb-10 px-5 overflow-y-auto md:overflow-x-auto md:overflow-y-visible md:snap-x md:snap-mandatory scroll-smooth custom-scrollbar">
          {/* Flex container con dirección responsiva */}
          <div className="flex flex-col md:flex-row justify-start items-center space-y-10 md:space-y-0 md:space-x-10">
            {team.map((member) => (
              <div
                key={member.name}
                className="relative flex flex-col shadow-lg group md:snap-start md:flex-shrink-0"
              >
                <div className="relative overflow-hidden w-72 h-72">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-full object-cover rounded-lg transition-all duration-500 group-hover:opacity-0"
                  />
                  <img
                    src={member.gif}
                    alt={`${member.name} gif`}
                    className="absolute top-0 left-0 w-full h-full object-cover rounded-lg transition-all duration-500 opacity-0 group-hover:opacity-100"
                  />
                </div>

                <div className="absolute top-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-all duration-500">
                  <p className="italic font-black text-white text-xl text-left py-5 pl-4 pr-24">
                    {member.text}
                  </p>
                </div>

                <p
                  className={`text-white text-3xl text-left font-semibold mt-5 pl-2 
          group-hover:text-transparent bg-clip-text bg-gradient-to-r ${member.gradient}`}
                >
                  {member.name}
                </p>
                <p className="text-[#3D3D3D] text-xl text-left pl-2">
                  {member.role}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;

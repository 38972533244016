import React from 'react';

const LinkedinIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 0.75C17.1641 0.75 17.75 1.33594 17.75 2.03906V17C17.75 17.7031 17.1641 18.25 16.5 18.25H1.46094C0.796875 18.25 0.25 17.7031 0.25 17V2.03906C0.25 1.33594 0.796875 0.75 1.46094 0.75H16.5ZM5.52344 15.75V7.42969H2.94531V15.75H5.52344ZM4.23438 6.25781C5.05469 6.25781 5.71875 5.59375 5.71875 4.77344C5.71875 3.95312 5.05469 3.25 4.23438 3.25C3.375 3.25 2.71094 3.95312 2.71094 4.77344C2.71094 5.59375 3.375 6.25781 4.23438 6.25781ZM15.25 15.75V11.1797C15.25 8.95312 14.7422 7.19531 12.125 7.19531C10.875 7.19531 10.0156 7.89844 9.66406 8.5625H9.625V7.42969H7.16406V15.75H9.74219V11.6484C9.74219 10.5547 9.9375 9.5 11.3047 9.5C12.6328 9.5 12.6328 10.75 12.6328 11.6875V15.75H15.25Z"
      fill="white"
    />
  </svg>
);

export default LinkedinIcon;

import React, { useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai'; // Usa un icono de check

const ContactForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [formData, setFormData] = useState({
    nombre: '',
    correo: '',
    telefono: '',
    empresa: '',
    comentario: '',
    hora_de_envio: new Date().toLocaleString() // Agrega la hora de envío
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitted(true);
    console.log("Datos del formulario:", formData); // Muestra los datos del formulario en la consola  

    fetch("https://api.sheetbest.com/sheets/fcea0f8c-3f27-4db7-bc42-495d976229b2", {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        "X-API-KEY": "fk36YcsvJE2ix7!Z@y6VIqlRI%N_R715QxfNLSXL#Yir62ix6Xvz$IpmTaJ5B9dW"
      },
      body: JSON.stringify(formData),
    })
      .then((r) => r.json())
      .then((data) => {
        // La respuesta viene aquí
        console.log("Respuesta del servidor:", data);
        setTimeout(() => {
          setIsSubmitted(false); // Reinicia el estado después de unos segundos si deseas que desaparezca
          setFormData({
            nombre: '',
            correo: '',
            telefono: '',
            empresa: '',
            comentario: '',
            hora_de_envio: new Date().toLocaleString()
          }); // Opcional: Reinicia los campos del formulario
        }, 2000);
      })
      .catch((error) => {
        // Se reportan los errores aquí
        console.log("Error al enviar el formulario:", error);
        setIsSubmitted(false);
      });
  };

  return (
    <section className="w-full min-h-screen bg-[#050505] flex items-center justify-center p-10">
      <div className="flex flex-col items-center w-full md:w-3/5 mt-20">
        <div className="text-4xl md:text-5xl font-black flex flex-col items-center md:flex-row md:justify-center mb-6">
          <h1 className="text-white">ESTEMOS EN</h1>
          <h1 className="italic text-yellow md:before:content-[' '] md:before:inline-block md:before:w-4">
            CONTACTO
          </h1>
        </div>
        <div className="text-center text-lg text-[#909090] w-[290px] md:w-[550px] mb-6 leading-tight">
          <p>
            Completa el formulario y nos pondremos en contacto contigo para
            conversar sobre tu proyecto y sus requerimientos
          </p>
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col items-center w-full space-y-6 mt-5 text-lg text-[#B4B4B4] px-10 md:px-0"
        >
          <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 w-full gap-x-6">
            <input
              type="text"
              name="nombre"
              placeholder="Nombre"
              value={formData.nombre}
              onChange={handleChange}
              className="bg-[#0D0D0D] placeholder-[#B4B4B4] text-[#B4B4B4] w-full md:w-1/2 h-14 px-4 rounded"
            />
            <input
              type="email"
              name="correo"
              placeholder="Correo"
              value={formData.correo}
              onChange={handleChange}
              className="bg-[#0D0D0D] placeholder-[#B4B4B4] w-full md:w-1/2 h-14 px-4 rounded"
            />
          </div>
          <div className="flex flex-col md:flex-row space-y-8 md:space-y-0 w-full gap-x-6">
            <div className="relative w-full md:w-1/2">
              <span className="absolute top-1/2 left-4 transform -translate-y-1/2 text-[#B4B4B4] pointer-events-none">
                +56
              </span>
              <input
                type="tel"
                name="telefono"
                placeholder="Teléfono"
                value={formData.telefono}
                onChange={handleChange}
                className="bg-[#0D0D0D] placeholder-[#B4B4B4] w-full h-14 pl-20 pr-4 rounded"
              />
            </div>
            <input
              type="text"
              name="empresa"
              placeholder="Empresa"
              value={formData.empresa}
              onChange={handleChange}
              className="bg-[#0D0D0D] placeholder-[#B4B4B4] w-full md:w-1/2 h-14 px-4 rounded"
            />
          </div>
          <textarea
            name="comentario"
            placeholder="Comentános en qué te podemos ayudar"
            value={formData.comentario}
            onChange={handleChange}
            className="hidden md:block bg-[#0D0D0D] placeholder-[#B4B4B4] w-full h-64 max-h-96 min-h-14 px-4 py-2 rounded"
          />
          <button
            type="submit"
            className="relative w-full h-14 text-white font-bold rounded border border-[#FCFCFD] overflow-hidden group"
          >
            <span className="absolute inset-0 bg-yellow transform origin-bottom scale-y-0 group-hover:scale-y-100 transition-all duration-700 ease-in-out"></span>
            <span className="relative z-10 group-hover:text-white">
              {isSubmitted ? (
                <AiOutlineCheck className="text-3xl animate-bounce mx-auto" /> // Icono de check animado centrado
              ) : (
                <span className="flex justify-center">ENVIAR</span>
              )}
            </span>
          </button>
        </form>
      </div>
    </section>
  );
};

export default ContactForm;

import React from 'react';

const NuevaAgricolaLogo: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 280 190.22"
  >
    <g>
      <polygon points="14.96 183.91 2.18 166.92 0 166.92 0 189.89 3.11 189.89 3.11 172.83 15.88 189.89 18.07 189.89 18.07 166.92 14.96 166.92 14.96 183.91" />
      <path d="M38.32,181.35c0,1.19-.25,2.23-.74,3.11-.5.88-1.17,1.57-2.02,2.05-.85.49-1.84.73-2.96.73s-2.06-.24-2.93-.73c-.87-.48-1.56-1.17-2.07-2.05-.51-.88-.76-1.93-.76-3.14v-14.39h-3.11v14.46c0,1.7.39,3.22,1.16,4.55.77,1.34,1.82,2.38,3.16,3.14,1.33.76,2.85,1.14,4.55,1.14s3.24-.38,4.57-1.14c1.32-.76,2.37-1.8,3.14-3.13.77-1.32,1.16-2.83,1.16-4.53v-14.49h-3.14v14.43Z" />
      <polygon points="50.2 179.5 61.29 179.5 61.29 176.78 50.2 176.78 50.2 169.77 62.21 169.77 62.21 166.92 50.2 166.92 49.21 166.92 47.09 166.92 47.09 189.89 49.21 189.89 50.2 189.89 62.38 189.89 62.38 187.04 50.2 187.04 50.2 179.5" />
      <polygon points="82.34 166.92 75.31 184.78 68.34 166.92 64.96 166.92 74.16 189.89 76.41 189.89 85.68 166.92 82.34 166.92" />
      <path d="M94.48,166.92l-9.4,22.97h3.31l2.08-5.2h10.19l2.06,5.2h3.38l-9.37-22.97h-2.25ZM91.56,181.98l4.03-10.06,3.99,10.06h-8.02Z" />
      <path d="M123.54,166.92l-9.4,22.97h3.31l2.08-5.2h10.19l2.06,5.2h3.38l-9.37-22.97h-2.25ZM120.61,181.98l4.03-10.06,3.99,10.06h-8.02Z" />
      <path d="M147.8,180.49h7.96c-.13,1.16-.42,2.19-.88,3.06-.64,1.2-1.55,2.11-2.73,2.73s-2.58.93-4.19.93-3-.38-4.23-1.13c-1.24-.75-2.22-1.79-2.95-3.11-.73-1.32-1.09-2.86-1.09-4.6s.36-3.24,1.08-4.57c.71-1.32,1.72-2.36,3.01-3.11s2.77-1.13,4.45-1.13c1.41,0,2.71.28,3.9.84s2.15,1.37,2.88,2.43l2.15-2.15c-1.04-1.32-2.33-2.34-3.87-3.04-1.55-.71-3.23-1.06-5.06-1.06-1.68,0-3.23.3-4.65.89-1.42.6-2.66,1.43-3.72,2.51s-1.89,2.33-2.48,3.76c-.6,1.42-.89,2.96-.89,4.62s.3,3.2.89,4.63c.6,1.44,1.42,2.69,2.47,3.77,1.05,1.08,2.27,1.93,3.67,2.53,1.4.61,2.91.91,4.52.91,2.14,0,4.04-.45,5.71-1.36s2.97-2.26,3.9-4.05c.94-1.8,1.41-4.03,1.41-6.7v-.46h-11.25v2.85Z" />
      <path d="M175.96,179.08c1.08-.54,1.91-1.29,2.5-2.27.58-.97.88-2.11.88-3.41s-.29-2.37-.88-3.34c-.59-.97-1.42-1.74-2.5-2.3s-2.35-.84-3.8-.84h-8.44v22.97h3.11v-9.99h2.03l8.1,9.99h3.94l-8.26-10.02c1.25-.05,2.37-.31,3.33-.79ZM166.82,169.64h5.23c1.35,0,2.38.36,3.09,1.09.72.73,1.08,1.64,1.08,2.75,0,1.17-.36,2.1-1.09,2.78-.73.68-1.76,1.03-3.11,1.03h-5.2v-7.64Z" />
      <polygon points="182.84 163.22 184.2 164.57 189.49 160.24 187.14 157.89 182.84 163.22" />
      <rect x="184.59" y="166.92" width="3.11" height="22.97" />
      <path d="M207.76,186.53c-1.06.47-2.31.71-3.74.71-1.21,0-2.33-.22-3.34-.65-1.01-.43-1.9-1.04-2.65-1.84s-1.33-1.73-1.74-2.81c-.41-1.08-.61-2.26-.61-3.54s.2-2.46.61-3.54c.41-1.08.99-2.02,1.74-2.81s1.63-1.41,2.65-1.84c1.02-.43,2.13-.65,3.34-.65,1.35,0,2.54.23,3.59.69,1.05.46,1.94,1.1,2.66,1.92l2.15-2.15c-1.06-1.06-2.27-1.9-3.64-2.51s-2.96-.93-4.77-.93c-1.66,0-3.18.3-4.58.91s-2.63,1.44-3.67,2.51c-1.05,1.07-1.86,2.32-2.43,3.76-.57,1.44-.86,2.98-.86,4.63s.29,3.17.86,4.6c.57,1.44,1.38,2.69,2.43,3.77,1.05,1.08,2.28,1.93,3.69,2.53,1.41.61,2.95.91,4.6.91,1.79,0,3.39-.31,4.82-.93,1.42-.62,2.66-1.48,3.72-2.58l-2.15-2.12c-.73.82-1.62,1.46-2.68,1.94Z" />
      <path d="M234.6,170c-1.06-1.08-2.29-1.92-3.69-2.51-1.4-.6-2.92-.89-4.55-.89s-3.15.3-4.55.89c-1.4.6-2.63,1.43-3.67,2.51s-1.86,2.33-2.45,3.76c-.59,1.42-.88,2.96-.88,4.62s.29,3.17.88,4.6c.58,1.43,1.41,2.7,2.46,3.79,1.06,1.09,2.29,1.94,3.71,2.55s2.93.91,4.57.91,3.11-.3,4.52-.91c1.4-.61,2.62-1.45,3.67-2.53,1.05-1.08,1.87-2.34,2.47-3.77.6-1.43.89-2.97.89-4.6s-.3-3.2-.89-4.63c-.6-1.43-1.42-2.69-2.48-3.77ZM233.67,182.96c-.73,1.33-1.72,2.38-2.96,3.14-1.25.76-2.7,1.14-4.35,1.14-1.21,0-2.33-.22-3.36-.66-1.03-.44-1.91-1.05-2.65-1.84-.74-.78-1.31-1.72-1.72-2.81-.41-1.09-.61-2.28-.61-3.56,0-1.72.36-3.24,1.08-4.57.72-1.32,1.7-2.36,2.95-3.11,1.25-.75,2.69-1.13,4.32-1.13,1.23,0,2.36.22,3.38.65,1.01.43,1.9,1.04,2.65,1.84s1.34,1.73,1.75,2.8.63,2.25.63,3.52c0,1.72-.36,3.25-1.09,4.58Z" />
      <polygon points="245.85 166.92 242.74 166.92 242.74 189.89 244.86 189.89 245.85 189.89 257.17 189.89 257.17 187.04 245.85 187.04 245.85 166.92" />
      <path d="M276.62,189.89h3.38l-9.37-22.97h-2.25l-9.4,22.97h3.31l2.08-5.2h10.19l2.06,5.2ZM265.46,181.98l4.03-10.06,3.99,10.06h-8.02Z" />
    </g>
    <g>
      <polygon
        fill="#e00110"
        points="62.17 139.11 43.58 139.11 68.85 0 134.48 0 198.37 114.72 215.22 17.43 178.34 17.43 173.4 55.18 160.04 29.91 164.98 0 236.42 0 212.31 139.11 192.28 139.11 123.74 17.43 83.37 17.43 62.17 139.11"
      />
      <polygon
        fill="#e00110"
        points="150.16 139.11 110.67 67.09 98.18 139.11 115.9 139.11 119.14 118.65 131.29 139.11 150.16 139.11"
      />
    </g>
  </svg>
);

export default NuevaAgricolaLogo;

import React from 'react';

const PlusIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 5H7V0H5V5H0V7H5V12H7V7H12V5Z" fill="black" />
  </svg>
);

export default PlusIcon;

import React from 'react';

const ExitIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.707 1.707L12.293 0.293L7 5.586L1.707 0.293L0.292999 1.707L5.586 7L0.292999 12.293L1.707 13.707L7 8.414L12.293 13.707L13.707 12.293L8.414 7L13.707 1.707Z"
      fill="white"
    />
  </svg>
);

export default ExitIcon;

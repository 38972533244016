import React from 'react';

const ArrowUpIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
  <svg
    {...props}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.29303 11.293L4.70703 12.707L11 6.414V20H13V6.414L19.293 12.707L20.707 11.293L12 2.586L3.29303 11.293Z"
      fill="#0D0D0D"
    />
  </svg>
);

export default ArrowUpIcon;

import React, { useEffect, useRef, useState } from 'react';
import logo from '../assets/Icono_Blackend.svg';
import AnimatedText from './AnimatedText';
import HappyIcon from './icons/HappyIcon';
import SettingsIcon from './icons/SettingsIcon';
import SquareIcon from './icons/SquareIcon';

const Home: React.FC = () => {
  const textSectionRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (textSectionRef.current) {
        const sectionTop = textSectionRef.current.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;
        const progress = Math.min(
          1,
          Math.max(0, (windowHeight - sectionTop) / windowHeight),
        );
        setScrollPosition(progress);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const renderAnimatedText = (
    text: string,
    isHighlighted: boolean = false,
    extraScrollPosition: number = 0,
  ) => {
    return text.split(' ').map((word, index) => {
      const opacity = Math.min(
        1,
        Math.max(0, scrollPosition + extraScrollPosition - index * 0.05),
      );

      return (
        <span
          key={index}
          className={`inline-block transition-opacity duration-500 ${
            isHighlighted ? 'text-yellow' : 'text-white'
          }`}
          style={{ opacity }}
        >
          {word}&nbsp;
        </span>
      );
    });
  };

  const scrollToId = (id: string) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
      section.focus();
    }
  };

  return (
    <section className="w-full bg-black text-white flex flex-col items-center md:py-10 py-40">
      <div className="flex flex-col justify-center items-center md:min-h-screen w-4/5 px-6 md:px-12">
        <img src={logo} className="h-12 mx-auto" alt="Logo" />
        <h1 className="text-center text-4xl md:text-7xl lg:text7xl font-medium mx-auto mt-8 mb-2 md:mb-6 leading-tight">
          CREAMOS PRODUCTOS DE SOFTWARE QUE IMPULSAN
        </h1>
        <AnimatedText />
        <div className="flex justify-center items-center my-6 w-full md:w-2/3 lg:w-1/2">
          <p className="font-light text-lg text-center">
            Desarrollamos para los que saben que el software debe evolucionar
            junto al negocio.
          </p>
        </div>
        <div className="flex flex-col md:flex-row justify-center space-y-4 md:space-y-0 md:space-x-6 mt-4">
          <button
            className="bg-black text-white font-semibold text-base md:text-lg rounded border-2 border-white px-5 py-2"
            onClick={() => scrollToId('success-stories')}
          >
            CONOCE NUESTROS PROYECTOS
          </button>
          <button
            className="bg-white text-black font-semibold text-base md:text-lg rounded px-5 py-2"
            onClick={() => scrollToId('contact-form')}
          >
            CONVERSEMOS
          </button>
        </div>
      </div>

      <div
        ref={textSectionRef}
        className="flex flex-col items-center min-h-[50vh] md:min-h-[60vh] text-2xl md:text-4xl lg:text-5xl my-20 md:mt-8 px-6 md:px-28"
      >
        <div className="text-left leading-tight px-10">
          <p className="transition-colors duration-1000">
            {renderAnimatedText(
              'Ofrecemos células de desarrollo para mantención de plataformas y llevar a cabo',
              false,
              0.2,
            )}
          </p>
          <p className="font-bold transition-colors duration-1000">
            {renderAnimatedText('desarrollo de software web y mobile a', true)}
          </p>
          <div className="flex flex-wrap">
            <p className="font-bold transition-colors duration-1000 mr-2">
              {renderAnimatedText('medida,', true)}
            </p>
            <p className="transition-colors duration-1000">
              {renderAnimatedText('automatizaciones,')}
            </p>
          </div>
          <p className="transition-colors duration-1000">
            {renderAnimatedText(
              'implementaciones con inteligencia artificial, diseño e implementación de MVPs.',
            )}
          </p>
        </div>
      </div>
      <div className="flex justify-center items-center min-h-[50vh] md:min-h-[40vh] w-full bg-black">
        <div className="flex flex-col md:flex-row justify-center items-center space-y-6 md:space-y-0 md:space-x-8 px-4 md:px-12">
          {/* Tarjeta 1 */}
          <div className="rounded-md w-full max-w-sm h-auto border-2 border-white p-6 md:p-8 hover:bg-yellow-400 hover:border-transparent transition-all flex flex-col justify-start items-start">
            <HappyIcon className="h-12 md:h-16 mb-6 md:mb-8" />
            <p className="text-base md:text-lg text-left">
              Trabajo cercano y en conjunto con el cliente, la cercanía es parte
              del éxito.
            </p>
          </div>
          {/* Tarjeta 2 */}
          <div className="rounded-md w-full max-w-sm h-auto border-2 border-white p-6 md:p-8 hover:bg-yellow-400 hover:border-transparent transition-all flex flex-col justify-start items-start">
            <SettingsIcon className="h-12 md:h-16 mb-6 md:mb-8" />
            <p className="text-base md:text-lg text-left">
              Uso de metodologías ágiles, nos permiten ser flexibles y apuntar a
              entregar valor pronto.
            </p>
          </div>
          {/* Tarjeta 3 */}
          <div className="rounded-md w-full max-w-sm h-auto border-2 border-white p-6 md:p-8 hover:bg-yellow-400 hover:border-transparent transition-all flex flex-col justify-start items-start">
            <SquareIcon className="h-12 md:h-16 mb-6 md:mb-8" />
            <p className="text-base md:text-lg text-left">
              Equipos conformados en torno a tus necesidades.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
